import React from 'react';

import loader from 'assets/images/loader.svg';
import { ReturnComponentType } from 'common';
import './Loader.scss';
import { useAppSelector } from 'hooks';
import { selectLoading } from 'state';

export const Loader = (): ReturnComponentType => {
  const isLoading = useAppSelector(selectLoading);

  return isLoading ? <img src={loader} alt="loading-logo" className="Loader" /> : null;
};
