import { SubscriptionType } from 'enums';

export const getSubscriptionDescriptionsFromTitle = (
  title: SubscriptionType,
): string[] => {
  if (!title) return [];
  if (title === SubscriptionType.STANDARD)
    return [
      'Unlimited App to Mobile calls all over the world',
      `Choosing the 20 most popular languages for caller's speech translation`,
      'Possibility to make a conference call with two interlocutors simultaneously(for web version only)',
    ];
  if (title === SubscriptionType.PREMIUM)
    return [
      'Unlimited App to Mobile and App to App calls all over the world',
      `Choosing the 40 most popular languages for caller's speech translation`,
      'Possibility to make a conference call with four interlocutors simultaneously(for web version only)',
    ];

  return [];
};
