import { AdminStateType, CallsStatisticType, StatisticType } from './types';

import { AdminActionType, LsKeys } from 'enums';
import { AdminActionReturnType } from 'state/actions';
import { getSettingsFromLS } from 'utils';

const initState: AdminStateType = {
  calls: [],
  users: [],
  statistic: {} as StatisticType,
  dashboardSettings: {
    country: getSettingsFromLS('country', LsKeys.DASHBOARD_SETTINGS, undefined),
    year: getSettingsFromLS('year', LsKeys.DASHBOARD_SETTINGS, undefined),
  },
  usersSettings: {
    isBlocked: getSettingsFromLS('isBlocked', LsKeys.USERS_SETTINGS, undefined),
    isSubscribed: getSettingsFromLS('isSubscribed', LsKeys.USERS_SETTINGS, undefined),
    dateTo: getSettingsFromLS('dateTo', LsKeys.USERS_SETTINGS, null),
    dateFrom: getSettingsFromLS('dateFrom', LsKeys.USERS_SETTINGS, null),
    page: getSettingsFromLS('page', LsKeys.USERS_SETTINGS, 1),
    q: getSettingsFromLS('q', LsKeys.USERS_SETTINGS, ''),
    sort: getSettingsFromLS('sort', LsKeys.USERS_SETTINGS, ''),
    asc: getSettingsFromLS('asc', LsKeys.USERS_SETTINGS, 'asc'),
  },
  usersCount: 0,
  callsSettings: {
    page: getSettingsFromLS('page', LsKeys.CALLS_SETTINGS, 1),
    q: getSettingsFromLS('q', LsKeys.CALLS_SETTINGS, ''),
    sort: getSettingsFromLS('sort', LsKeys.CALLS_SETTINGS, 'callStartDate'),
    asc: getSettingsFromLS('asc', LsKeys.CALLS_SETTINGS, 'asc'),
  },
  callsCount: 0,
  countryList: [],
  callsStatistic: {} as CallsStatisticType,
  dashboardHeaderSettings: {
    month: getSettingsFromLS('month', LsKeys.DASHBOARD_HEADER_SETTINGS, 0),
    year: getSettingsFromLS('year', LsKeys.DASHBOARD_HEADER_SETTINGS, 2023),
  },
  articles: [],
  articlesCount: 0,
  articlesSettings: {
    page: getSettingsFromLS('page', LsKeys.ARTICLES_SETTINGS, 1),
  },
};

export const adminReducer = (
  // eslint-disable-next-line default-param-last
  state: AdminStateType = initState,
  action: AdminActionReturnType,
): AdminStateType => {
  switch (action.type) {
    case AdminActionType.SET_STATISTIC:
      return { ...state, statistic: action.statistic };
    case AdminActionType.SET_CALLS:
      return { ...state, calls: action.calls };
    case AdminActionType.SET_USERS:
      return { ...state, users: action.users };
    case AdminActionType.SET_USERS_SETTINGS:
      return {
        ...state,
        usersSettings: { ...state.usersSettings, ...action.usersSettings },
      };
    case AdminActionType.SET_CALLS_SETTINGS:
      return {
        ...state,
        callsSettings: { ...state.callsSettings, ...action.callsSettings },
      };
    case AdminActionType.SET_DASHBOARD_SETTINGS:
      return { ...state, dashboardSettings: action.dashboardSettings };
    case AdminActionType.SET_USERS_COUNT:
      return { ...state, usersCount: action.usersCount };
    case AdminActionType.SET_CALLS_COUNT:
      return { ...state, callsCount: action.callsCount };
    case AdminActionType.SET_COUNTRY_LIST:
      return { ...state, countryList: action.countryList };
    case AdminActionType.SET_CALLS_STATISTIC:
      return { ...state, callsStatistic: action.callsStatistic };
    case AdminActionType.SET_DASHBOARD_HEADER_SETTINGS:
      return { ...state, dashboardHeaderSettings: action.dashboardHeaderSettings };
    case AdminActionType.SET_ARTICLES:
      return { ...state, articles: action.articles };
    case AdminActionType.SET_ARTICLES_COUNT:
      return { ...state, articlesCount: action.articlesCount };
    case AdminActionType.SET_ARTICLES_SETTINGS:
      return {
        ...state,
        articlesSettings: { ...state.articlesSettings, ...action.articlesSettings },
      };
    default:
      return state;
  }
};
