import React from 'react';

import { CommonModalType, ReturnComponentType } from 'common';
import { CommonModal } from 'components/Modals/CommonModal';
import { useTypedDispatch } from 'hooks';
import { deleteSelf, logOut } from 'state';
import { Button } from 'ui-kit';
import { checkIsGoodStatus } from 'utils';
import './DeleteSelfModal.scss';

export const DeleteSelfModal = ({
  showModal,
  setShowModal,
}: CommonModalType): ReturnComponentType => {
  const dispatch = useTypedDispatch();
  const onNoClick = () => {
    setShowModal(false);
  };
  const onYesClick = async () => {
    const status = await dispatch(deleteSelf());

    if (checkIsGoodStatus(status)) {
      dispatch(logOut());
      setShowModal(false);
    }
  };

  return (
    <CommonModal showModal={showModal} closeModal={onNoClick}>
      <section className="DeleteSelfModal">
        <h4>Delete account</h4>
        <span>
          Deleting your account will remove all of your information from out database.
          This cannot be undone
        </span>
        <div className="DeleteSelfModal_ButtonsGroup">
          <Button onClick={onNoClick} className="DeleteSelfModal_ButtonsGroup_Button">
            Cancel
          </Button>
          <Button onClick={onYesClick} className="DeleteSelfModal_ButtonsGroup_Button">
            Delete
          </Button>
        </div>
      </section>
    </CommonModal>
  );
};
