import { ArticleType, CallType } from 'api';
import { GeneralSettingsType } from 'common';
import { AppRootStateType } from 'state/reducers';
import {
  CallsStatisticType,
  DashboardHeaderSettingsType,
  DashboardSettingsType,
  StatisticType,
  UsersSettingsType,
} from 'state/reducers/admin/types';
import { UserType } from 'state/reducers/auth/types';

export const selectStatistic = (state: AppRootStateType): StatisticType =>
  state.admin.statistic;
export const selectUsers = (state: AppRootStateType): UserType[] => state.admin.users;
export const selectCalls = (state: AppRootStateType): CallType[] => state.admin.calls;
export const selectUsersCount = (state: AppRootStateType): number =>
  state.admin.usersCount;
export const selectCallsCount = (state: AppRootStateType): number =>
  state.admin.callsCount;
export const selectUsersSettings = (state: AppRootStateType): UsersSettingsType =>
  state.admin.usersSettings;
export const selectCallsSettings = (
  state: AppRootStateType,
): GeneralSettingsType & { asc?: 'asc' | 'desc' } => state.admin.callsSettings;
export const selectCountryList = (state: AppRootStateType): string[] =>
  state.admin.countryList;
export const selectDashboardSettings = (state: AppRootStateType): DashboardSettingsType =>
  state.admin.dashboardSettings;
export const selectCallsStatistic = (state: AppRootStateType): CallsStatisticType =>
  state.admin.callsStatistic;
export const selectDashboardHeaderSettings = (
  state: AppRootStateType,
): DashboardHeaderSettingsType => state.admin.dashboardHeaderSettings;
export const selectArticles = (state: AppRootStateType): ArticleType[] =>
  state.admin.articles;
export const selectArticlesSettings = (state: AppRootStateType): { page: number } =>
  state.admin.articlesSettings;
export const selectArticlesCount = (state: AppRootStateType): number =>
  state.admin.articlesCount;
