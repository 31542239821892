import React, { useState } from 'react';

import cn from 'classnames';

import paypalLogo from 'assets/images/logos_paypal.svg';
import stripeLogo from 'assets/images/logos_stripe.svg';
import { CommonModalType, ReturnComponentType } from 'common';
import { CommonModal } from 'components/Modals/CommonModal';
import { PaymentMethod, SubscriptionType } from 'enums';
import { useTypedDispatch } from 'hooks';
import { createCheckoutSession, generatePaypalToken } from 'state';
import { Button, CheckboxInput } from 'ui-kit';
import { checkIsGoodStatus } from 'utils';

import './ChoosePaymentModal.scss';

type ChoosePaymentModalType = CommonModalType & {
  defaultPrice: string;
  title: SubscriptionType;
  setShowBraintreeDropInModal: (showBraintreeDropInModal: boolean) => void;
};
export const ChoosePaymentModal = ({
  showModal,
  setShowModal,
  defaultPrice,
  title,
  setShowBraintreeDropInModal,
}: ChoosePaymentModalType): ReturnComponentType => {
  const dispatch = useTypedDispatch();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const onCloseClick = () => {
    setShowModal(false);
  };

  const onPaymentMethodClick = (method: PaymentMethod) => {
    setPaymentMethod(method);
  };
  const onContinueClick = async () => {
    if (paymentMethod === PaymentMethod.STRIPE) {
      const response = await dispatch(createCheckoutSession(defaultPrice, title));

      if (checkIsGoodStatus(response?.status)) {
        window.location.replace(`${response?.data?.url}`);
      }
    }
    if (paymentMethod === PaymentMethod.PAYPAL) {
      const status = await dispatch(generatePaypalToken());

      if (checkIsGoodStatus(status)) {
        setShowBraintreeDropInModal(true);
      }
    }
    setShowModal(false);
  };

  const checkedStripe = paymentMethod === PaymentMethod.STRIPE;

  const checkedPayPal = paymentMethod === PaymentMethod.PAYPAL;

  return (
    <CommonModal showModal={showModal} closeModal={onCloseClick}>
      <section className="ChoosePaymentModal">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onClick={onCloseClick} className="ChoosePaymentModal_Close">
          &#10006;
        </div>
        <h4>Choose a way to pay</h4>
        <div className="ChoosePaymentModal_Box">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => onPaymentMethodClick(PaymentMethod.STRIPE)}
            className={cn(
              'ChoosePaymentModal_Box_Method',
              checkedStripe && 'ChoosePaymentModal_Box_Method__active',
            )}
          >
            <CheckboxInput
              checked={checkedStripe}
              className="ChoosePaymentModal_Box_Method_CheckBox"
            />
            <img src={stripeLogo} alt="stripe-logo" />
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => onPaymentMethodClick(PaymentMethod.PAYPAL)}
            className={cn(
              'ChoosePaymentModal_Box_Method',
              checkedPayPal && 'ChoosePaymentModal_Box_Method__active',
            )}
          >
            <CheckboxInput
              checked={checkedPayPal}
              className="ChoosePaymentModal_Box_Method_CheckBox"
            />
            <img src={paypalLogo} alt="paypal-logo" />
          </div>
        </div>
        <Button
          onClick={onContinueClick}
          className="ChoosePaymentModal_Continue"
          disabled={!paymentMethod}
        >
          Continue
        </Button>
      </section>
    </CommonModal>
  );
};
