import {
  changeArticleRequest,
  changeUserStatusRequest,
  createArticleRequest,
  deleteArticleRequest,
  deleteCallsRequest,
  deleteUsersRequest,
  getArticlesRequest,
  getCallsRequest,
  getCallStatisticRequest,
  getCountryListRequest,
  getStatisticRequest,
  getUsersRequest,
  inviteMemberRequest,
} from 'api';
import {
  CreateArticleRequestType,
  GetCallStatisticRequestType,
} from 'api/adminApi/types';
import { InviteMemberRequestType } from 'api/authApi/types';
import { Pagination } from 'enums/Pagination';
import {
  setAuthLoading,
  setCallsAction,
  setStatisticAction,
  setUsersAction,
  setUsersSettingsAction,
  setCallsCountAction,
  setCountryListAction,
  setUsersCountAction,
  setCallsStatisticAction,
  setArticlesAction,
  setArticlesCountAction,
  setArticlesSettingsAction,
} from 'state/actions';
import { AppRootStateType } from 'state/reducers';
import { TypedDispatch } from 'state/types';
import { checkIsGoodStatus } from 'utils';

export const getStatistic =
  () => async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const { month, year } = getState().admin.dashboardHeaderSettings;

    try {
      dispatch(setAuthLoading(true));
      const response = await getStatisticRequest({ month: month + 1, year: +year });

      dispatch(setStatisticAction(response?.data));

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const getCalls =
  () => async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const params = getState().admin.callsSettings;

    try {
      dispatch(setAuthLoading(true));
      const response = await getCallsRequest({ ...params, take: Pagination.TAKE });

      dispatch(setCallsAction(response?.data.rows));
      dispatch(setCallsCountAction(response?.data.count));

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const getUsers =
  () => async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const params = getState().admin.usersSettings;

    try {
      dispatch(setAuthLoading(true));
      const response = await getUsersRequest({ ...params, take: Pagination.TAKE });

      dispatch(setUsersAction(response?.data?.rows));
      dispatch(setUsersCountAction(response?.data?.count));
      dispatch(setUsersSettingsAction({ page: response?.data?.page }));

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const deleteUsers = (ids: string[]) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await deleteUsersRequest(ids);

    if (checkIsGoodStatus(response?.status)) {
      dispatch(getUsers());
    }

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const changeUserStatus = (id: string) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await changeUserStatusRequest(id);

    if (checkIsGoodStatus(response?.status)) {
      dispatch(getUsers());
    }

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const inviteMember =
  (data: InviteMemberRequestType) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));

      return await inviteMemberRequest(data);
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const getCallStatistic =
  () => async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const params: GetCallStatisticRequestType = {
      country: getState().admin.dashboardSettings.country,
      year: getState().admin.dashboardSettings.year,
      asc: 'desc',
      sort: 'month',
    };

    try {
      dispatch(setAuthLoading(true));
      const response = await getCallStatisticRequest(params);

      dispatch(setCallsStatisticAction(response?.data));

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const getCountryList = () => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));

    const response = await getCountryListRequest();

    dispatch(setCountryListAction(response?.data));

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const deleteCalls = (ids: string[]) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await deleteCallsRequest(ids);

    if (checkIsGoodStatus(response?.status)) {
      dispatch(getCalls());
    }

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const getArticles =
  () => async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const params = getState().admin.articlesSettings;

    try {
      dispatch(setAuthLoading(true));
      const response = await getArticlesRequest({
        ...params,
        take: Pagination.ARTICLE_TAKE,
      });

      dispatch(setArticlesAction(response?.data?.rows));
      dispatch(setArticlesCountAction(response?.data?.count));
      dispatch(
        setArticlesSettingsAction({
          page:
            response?.data?.page > response?.data?.lastPage && response?.data?.lastPage
              ? response?.data?.lastPage
              : response?.data?.page,
        }),
      );

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const createArticle =
  (data: CreateArticleRequestType) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await createArticleRequest(data);

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const deleteArticle = (id: string) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await deleteArticleRequest(id);

    if (checkIsGoodStatus(response?.status)) {
      dispatch(getArticles());
    }

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const changeArticle =
  (id: string, data: CreateArticleRequestType) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await changeArticleRequest(id, data);

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
