import axios from 'axios';

import { ApiPath } from 'enums';

export const authServiceClient = axios.create({
  baseURL: ApiPath.auth,
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});
