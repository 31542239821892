import { useCallback, useEffect, useState } from 'react';

import { CallType } from 'api';
import { UserType } from 'state';

export const useSelectPartItems = (items: UserType[] | CallType[]) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isSelectedAllItems, setIsSelectedAllItems] = useState(false);

  const selectAllItems = useCallback(
    (isSelect: boolean) => {
      setSelectedItems(isSelect ? items?.map(item => item.id) : []);
    },
    [items],
  );

  const selectOneItem = useCallback(
    (isSelect: boolean, id: string) => {
      setSelectedItems(
        isSelect ? [...selectedItems, id] : selectedItems.filter(itemId => itemId !== id),
      );
    },
    [selectedItems],
  );

  useEffect(() => {
    if (selectedItems?.length && selectedItems?.length === items?.length) {
      setIsSelectedAllItems(true);
    } else setIsSelectedAllItems(false);
  }, [selectedItems?.length, items]);

  return {
    selectedItems,
    isSelectedAllItems,
    selectOneItem,
    selectAllItems,
    setSelectedItems,
  };
};
