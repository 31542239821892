import React from 'react';

import appleLogo from 'assets/images/apple-logo.svg';
import googleLogo from 'assets/images/google-logo.svg';
import { ReturnComponentType } from 'common';
import { ApiPath } from 'enums';
import { Button } from 'ui-kit';
import './ExternalDataCardFooter.scss';

export const ExternalDataCardFooter = (): ReturnComponentType => {
  const onLinkClick = (path: string) => {
    window.location.replace(`${ApiPath.auth}/auth/${path}`);
  };

  return (
    <div className="ExternalDataCardFooter">
      <div className="ExternalDataCardFooter_Header">
        <div className="ExternalDataCardFooter_Header_Line" />
        <div className="ExternalDataCardFooter_Header_Or">OR</div>
        <div className="ExternalDataCardFooter_Header_Line" />
      </div>

      <div className="ExternalDataCardFooter_Box">
        <Button
          className="ExternalDataCardFooter_Box_Button"
          onClick={() => onLinkClick('google')}
        >
          <img
            src={googleLogo}
            alt="Google-logo"
            className="ExternalDataCardFooter_Box_Button_Logo_Google"
          />
        </Button>
        <Button
          className="ExternalDataCardFooter_Box_Button"
          onClick={() => onLinkClick('apple')}
        >
          <img
            src={appleLogo}
            alt="Apple-logo"
            className="ExternalDataCardFooter_Box_Button_Logo_Apple"
          />
        </Button>
      </div>
    </div>
  );
};
