import { ResponseEventType } from 'common';

export const getTextForMember = (
  memberLang: string,
  data: ResponseEventType[],
): string => {
  const filteredData = data?.filter(item => item?.lang === memberLang);

  if (!filteredData?.length) return '';

  return filteredData.map(item => item?.text)?.join('.');
};
