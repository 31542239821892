import React, { ReactNode, RefObject } from 'react';

import cn from 'classnames';

import { ReturnComponentType } from 'common';
import './PopUp.scss';

type PopUpType = {
  children: ReactNode;
  refObj: RefObject<any>;
  className?: string;
  onClick?: () => void;
};
export const PopUp = ({
  children,
  className,
  refObj,
  onClick,
}: PopUpType): ReturnComponentType => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <section className={cn('PopUp', className)} ref={refObj} onClick={onClick}>
      <div className="PopUp_Box">
        <div className="PopUp_Child">{children}</div>
      </div>
    </section>
  );
};
