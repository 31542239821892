import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { selectActiveCall, selectUser } from 'state';
import { clearRecipients, setCallTimeOver } from 'state/actions';
import { convertTime } from 'utils';

export type TimerType = { mins: number; sec: number };
export const useTimer = (): TimerType => {
  const dispatch = useTypedDispatch();
  const isActiveCall = useAppSelector(selectActiveCall);
  const user = useAppSelector(selectUser);
  const [timer, setTimer] = useState<TimerType>(convertTime(0));
  const getTime = () => {
    if (!user?.isSubscribed) {
      return setTimer(state => ({
        mins: state.sec === 0 ? state.mins - 1 : state.mins,
        sec: state.sec === 0 ? 59 : state.sec - 1,
      }));
    }

    setTimer(state => ({
      mins: state.sec === 59 ? state.mins + 1 : state.mins,
      sec: state.sec === 59 ? 0 : state.sec + 1,
    }));
  };

  useEffect(() => {
    if (user?.isSubscribed) {
      setTimer(convertTime(0));
    } else
      setTimer(
        convertTime(
          180 - (user?.totalCallTime ?? 0) > 0 ? 180 - (user?.totalCallTime ?? 0) : 0,
        ),
      );
  }, [user?.isSubscribed]);

  useEffect(() => {
    if (isActiveCall && !user?.isSubscribed && timer.mins <= 0 && timer.sec <= 0) {
      dispatch(setCallTimeOver(true));
      dispatch(clearRecipients());
    }
  }, [dispatch, isActiveCall, user?.isSubscribed, timer.mins, timer.sec]);

  useEffect(() => {
    if (isActiveCall) {
      const interval = setInterval(() => getTime(), 1000);

      return () => clearInterval(interval);
    }
  }, [isActiveCall]);

  return timer;
};
