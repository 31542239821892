import { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { AdminLayout, ExternalBaseComponent, ProtectedRoute } from 'components';
import { Paths } from 'enums';

const Login = lazy(() => import('pages/Login'));
const RestorePassword = lazy(() => import('pages/RestorePassword'));
const SignUp = lazy(() => import('pages/SignUp'));
const NewPassword = lazy(() => import('pages/NewPassword'));
const Main = lazy(() => import('pages/Main'));
const ResetLink = lazy(() => import('pages/ResetLink'));
const ActivatedLink = lazy(() => import('pages/ActivatedLink'));
const SuccessfulRegistration = lazy(() => import('pages/SuccessfulRegistration'));
const SuccessfulNewPassword = lazy(() => import('pages/SuccessfulNewPassword'));
const AdminLogin = lazy(() => import('pages/AdminLogin'));
const Subscription = lazy(() => import('pages/Subscription'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Calls = lazy(() => import('pages/Calls'));
const Users = lazy(() => import('pages/Users'));
const Empty = lazy(() => import('pages/Empty'));
const SuccessfulPayment = lazy(() => import('pages/SuccessfulPayment'));
const CanceledPayment = lazy(() => import('pages/CanceledPayment'));
const ResendLink = lazy(() => import('pages/ResendLink'));
const Article = lazy(() => import('pages/Article'));
const Press = lazy(() => import('pages/Press'));
const AdminPress = lazy(() => import('pages/AdminPress'));
const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={`${Paths.ADMIN}${Paths.DASHBOARD}`} />} />
      <Route path={Paths.DASHBOARD} element={<Dashboard />} />
      <Route path={Paths.USERS} element={<Users />} />
      <Route path={Paths.CALLS} element={<Calls />} />
      <Route path={`${Paths.PRESS}${Paths.ARTICLE}/:id?`} element={<Article />} />
      <Route path={Paths.PRESS} element={<AdminPress />} />
    </Routes>
  );
};

const InternalRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={Paths.MAIN} />} />
      <Route index element={<Main />} />
      <Route path={Paths.SUBSCRIPTION} element={<Subscription />} />
      <Route path={Paths.SUCCESS} element={<SuccessfulPayment />} />
      <Route path={Paths.CANCEL} element={<CanceledPayment />} />
      <Route
        path={`${Paths.ADMIN}/*`}
        element={
          <AdminLayout>
            <AdminRoutes />
          </AdminLayout>
        }
      />
    </Routes>
  );
};

const RestorePasswordRoutes = () => {
  return (
    <Routes>
      <Route index element={<RestorePassword />} />
      <Route path={Paths.RESET_LINK} element={<ResetLink />} />
      <Route path={Paths.SUCCESSFUL_NEW_PASSWORD} element={<SuccessfulNewPassword />} />
    </Routes>
  );
};
const SignUpRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ExternalBaseComponent>
            <SignUp />
          </ExternalBaseComponent>
        }
      />
      <Route path={Paths.ACTIVATED_LINK} element={<ActivatedLink />} />
    </Routes>
  );
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.SOCIAL_SUCCESS} element={<Empty />} />
      <Route
        path={Paths.LOGIN}
        element={
          <ExternalBaseComponent>
            <Login />
          </ExternalBaseComponent>
        }
      />
      <Route path={`${Paths.ADMIN}${Paths.LOGIN}`} element={<AdminLogin />} />
      <Route path={`${Paths.RESTORE_PASSWORD}/*`} element={<RestorePasswordRoutes />} />
      <Route path={Paths.RESET_PASSWORD} element={<NewPassword />} />
      <Route path={`${Paths.SIGN_UP}/*`} element={<SignUpRoutes />} />
      <Route path={Paths.VERIFY} element={<SuccessfulRegistration />} />
      <Route path={Paths.RESEND_LINK} element={<ResendLink />} />
      <Route path={Paths.PRESS} element={<Press />} />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <InternalRoutes />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
