import { useEffect } from 'react';

import { ApiPath, CallStatus } from 'enums';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { selectStartCall, selectUser } from 'state';
import {
  changeRecipient,
  changeRecipientCallStatus,
  hangUpRecipient,
} from 'state/actions/call/callActions';

export const useCallEvent = () => {
  const dispatch = useTypedDispatch();
  const user = useAppSelector(selectUser);
  const isStartCall = useAppSelector(selectStartCall);

  useEffect(() => {
    if (user?.id && isStartCall) {
      const sipSse = new EventSource(`${ApiPath.auth}/vonage/sse/sipWeb/${user?.id}`);

      sipSse.addEventListener(`app`, e => {
        const parseData = JSON.parse(e.data);

        if (parseData.type === 'member:invited') {
          dispatch(
            changeRecipient(
              parseData.body.channel.to.number,
              parseData.from,
              parseData.conversation_id,
            ),
          );
        }
        if (parseData.type === 'sip:ringing') {
          dispatch(changeRecipientCallStatus(parseData.from, CallStatus.RINGING));
        }

        if (parseData.type === 'sip:answered') {
          dispatch(changeRecipientCallStatus(parseData.from, CallStatus.ANSWERED));
        }

        if (parseData.type === 'sip:hangup') {
          dispatch(hangUpRecipient(parseData.from));
          // TODO fix bug when recipient hangup from conference and need change second language
        }
      });

      sipSse.onerror = e => {
        sipSse.close();
      };

      return () => {
        sipSse.close();
      };
    }
  }, [dispatch, isStartCall, user?.id]);
};
