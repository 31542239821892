import React, { ReactNode } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router';

import { ReturnComponentType } from 'common';
import { UserLayoutHeader } from 'components';
import { Paths } from 'enums';
import { useAppSelector } from 'hooks';
import { selectStartCall, selectUser } from 'state';
import './UserLayout.scss';

type UserLayoutType = {
  children: ReactNode;
  className?: string;
};

export const UserLayout = ({
  children,
  className,
}: UserLayoutType): ReturnComponentType => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const isStartCall = useAppSelector(selectStartCall);

  const onSubscriptionClick = async () => {
    if (!isStartCall) navigate(Paths.SUBSCRIPTION);
  };

  return (
    <section className={cn('UserLayout', className)}>
      <UserLayoutHeader />

      <div className="UserLayout_Child">{children}</div>

      {!user?.isSubscribed && (
        <footer className="UserLayout_Footer" onClick={onSubscriptionClick}>
          <h5 className="UserLayout_Footer_Title">Get Subscription</h5>
          <div className="UserLayout_Footer_Description">
            With a Subscription you get the opportunity to make unlimited calls from the
            application.
          </div>
        </footer>
      )}
    </section>
  );
};
