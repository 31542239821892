import { CallStateType } from './types';

import { CallActionType } from 'enums';
import { CallActionReturnType } from 'state/actions';

const initState: CallStateType = {
  recipients: [],
  isMutedApp: false,
  isCallTimeOver: false,
  isActiveCall: false,
  isStartCall: false,
  isShowNumbersWhenCall: false,
};

export const callReducer = (
  // eslint-disable-next-line default-param-last
  state: CallStateType = initState,
  action: CallActionReturnType,
): CallStateType => {
  switch (action.type) {
    case CallActionType.ADD_RECIPIENT:
      return { ...state, recipients: [...state.recipients, action.recipient] };
    case CallActionType.CLEAR_RECIPIENTS:
      return { ...state, recipients: [] };
    case CallActionType.HANG_UP_RECIPIENT:
      return {
        ...state,
        recipients: state.recipients.filter(
          recipient => recipient.id !== action.recipientId,
        ),
      };
    case CallActionType.CHANGE_RECIPIENT:
      return {
        ...state,
        recipients: state.recipients.map(recipient =>
          recipient.phone === action.recipientPhone
            ? {
                ...recipient,
                id: action.recipientId,
                conversationId: action.conversationId,
              }
            : recipient,
        ),
      };
    case CallActionType.MUTE_APP:
      return { ...state, isMutedApp: action.isMutedApp };
    case CallActionType.CALL_TIME_OVER:
      return { ...state, isCallTimeOver: action.isCallTimeOver };
    case CallActionType.ACTIVE_CALL:
      return { ...state, isActiveCall: action.isActiveCall };
    case CallActionType.CHANGE_RECIPIENT_CALL_STATUS:
      return {
        ...state,
        recipients: state.recipients.map(recipient =>
          recipient.id === action.recipientId
            ? {
                ...recipient,
                callStatus: action.callStatus,
              }
            : recipient,
        ),
      };
    case CallActionType.START_CALL:
      return { ...state, isStartCall: action.isStartCall };
    case CallActionType.SHOW_NUMBERS_WHEN_CALL:
      return { ...state, isShowNumbersWhenCall: action.isShowNumbersWhenCall };
    default:
      return state;
  }
};
