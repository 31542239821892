import React from 'react';

import { useNavigate } from 'react-router';

import { ArticleType } from 'api';
import { ReturnComponentType } from 'common';
import { Paths, Roles } from 'enums';
import { useAppSelector, useComponentVisible, useTypedDispatch } from 'hooks';
import { deleteArticle, selectUser } from 'state';
import { Button, PopUp, ThreeDots } from 'ui-kit';
import './ArticleDataCard.scss';

type ArticleDataCardType = {
  article: ArticleType;
};
export const ArticleDataCard = ({
  article: { id, title, resource, link },
}: ArticleDataCardType): ReturnComponentType => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const user = useAppSelector(selectUser);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onThreeDotsClick = () => {
    setIsComponentVisible(true);
  };
  const onDeleteArticleClick = () => {
    dispatch(deleteArticle(id));
  };
  const onEditArticleClick = () => {
    navigate(`${Paths.ADMIN}${Paths.PRESS}${Paths.ARTICLE}/${id}`);
  };
  const onPopUpClick = () => {
    setIsComponentVisible(false);
  };
  const onArticleClick = () => {
    window.location.replace(link);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <section className="ArticleDataCard">
      <div className="ArticleDataCard_Box">
        <h4 onClick={onArticleClick}>{title}</h4>

        {isComponentVisible && (
          <PopUp refObj={ref} onClick={onPopUpClick}>
            <Button
              onMouseUp={onEditArticleClick}
              className="ArticleDataCard_Box_PopUp_Button"
            >
              Edit
            </Button>

            <Button
              onMouseUp={onDeleteArticleClick}
              className="ArticleDataCard_Box_PopUp_Button"
            >
              Delete
            </Button>
          </PopUp>
        )}
        {user && user?.role === Roles.ADMIN && (
          <ThreeDots
            className="ArticleDataCard_Box_ThreeDots"
            onClick={onThreeDotsClick}
          />
        )}
      </div>

      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="ArticleDataCard_Magazine" onClick={onArticleClick}>
        {resource}
      </div>
    </section>
  );
};
