import React, { useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';

import userLogo from 'assets/images/user-logo.svg';
import { ReturnComponentType } from 'common';
import { DeleteSelfModal } from 'components/Modals/DeleteSelfModal';
import { SelectLanguage } from 'components/SelectLanguage/SelectLanguage';
import { Paths, Purpose, SubscriptionType } from 'enums';
import { useAppSelector, useComponentVisible, useTypedDispatch } from 'hooks';
import { logOut, selectShowNumbersWhenCall, selectStartCall, selectUser } from 'state';
import { Button, PopUp } from 'ui-kit';

import './UserLayoutHeader.scss';

export const UserLayoutHeader = (): ReturnComponentType => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const isStartCall = useAppSelector(selectStartCall);
  const isShowNumbersWhenCall = useAppSelector(selectShowNumbersWhenCall);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [showDeleteSelfModal, setShowDeleteSelfModal] = useState(false);
  const onLogoutClick = () => {
    dispatch(logOut());
  };
  const onPopUpClick = () => {
    setIsComponentVisible(false);
  };
  const onLogoClick = () => {
    if (!isStartCall) {
      setIsComponentVisible(true);
    }
  };
  const onSubscriptionClick = () => {
    if (user?.subscriptionType !== SubscriptionType.PREMIUM) navigate(Paths.SUBSCRIPTION);
  };
  const onChangePasswordClick = () => {
    navigate(Paths.RESET_PASSWORD);
  };

  const onDeleteClick = () => {
    setShowDeleteSelfModal(true);
  };

  return (
    <header className="UserLayoutHeader">
      <SelectLanguage purpose={Purpose.FROM} disabled={isStartCall} />
      <div className="UserLayoutHeader_Box">
        <SelectLanguage
          purpose={Purpose.TO}
          disabled={isStartCall && !isShowNumbersWhenCall}
        />
        <img
          src={userLogo}
          alt="user-logo"
          onClick={onLogoClick}
          className={cn('UserLayoutHeader_Box_Logo', isStartCall && 'disabled')}
        />
        {isComponentVisible && (
          <PopUp refObj={ref} onClick={onPopUpClick}>
            <Button
              onClick={onChangePasswordClick}
              className="UserLayoutHeader_Box_Button"
            >
              Change Password
            </Button>
            <Button className="UserLayoutHeader_Box_Button" onClick={onSubscriptionClick}>
              Subscription Plan:&nbsp;
              <span>
                {user?.subscriptionType ?? ''}&nbsp;
                {user?.subscriptionEndDate &&
                  dayjs(new Date()).to(user?.subscriptionEndDate)}
              </span>
            </Button>{' '}
            <Button onClick={onLogoutClick} className="UserLayoutHeader_Box_Button">
              Log out
            </Button>
            <Button onClick={onDeleteClick} className="UserLayoutHeader_Box_Button">
              Delete account
            </Button>
          </PopUp>
        )}
      </div>
      {showDeleteSelfModal && (
        <DeleteSelfModal
          showModal={showDeleteSelfModal}
          setShowModal={setShowDeleteSelfModal}
        />
      )}
    </header>
  );
};
