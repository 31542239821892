import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import mailLogo from 'assets/images/small-mail.svg';
import phoneLogo from 'assets/images/small-phone.svg';
import { ReturnComponentType } from 'common';
import './Footer.scss';
import { ShowTermsOfUseModal } from 'components/Modals/ShowTermsOfUseModal';
import { Paths } from 'enums';

export const Footer = (): ReturnComponentType => {
  const [isShowTermsOfUseModal, setIsShowTermsOfUseModal] = useState(false);
  const onPrivacyPolicyClick = () => {
    setIsShowTermsOfUseModal(true);
  };

  return (
    <footer className="Footer">
      <div className="Footer_Container">
        <Link to="tel:+12122020004" className="Footer_Container_Terms">
          <img src={phoneLogo} alt="phone-logo" />
          +1 212-202-0004
        </Link>
        <Link to="mailto:1@1voice.ai" className="Footer_Container_Terms">
          <img src={mailLogo} alt="maol-logo" />
          1@1voice.ai
        </Link>

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="Footer_Container_Terms" onClick={onPrivacyPolicyClick}>
          Privacy Policy
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <Link className="Footer_Container_Terms" to={Paths.PRESS}>
          Press
        </Link>
        <div className="Footer_Container_Terms">
          <Link to="https://commission.europa.eu/law/law-topic/data-protection_en">
            GDPR
          </Link>
          -<Link to="https://www.dmca.com/">DMCA</Link>-
          <Link to="https://oag.ca.gov/privacy/ccpa#:~:text=The%20CCPA%20applies%20to%20for,%2C%20households%2C%20or%20devices%3B%20or">
            CCPA
          </Link>{' '}
          <Link to="http://en.npc.gov.cn.cdurl.cn/2021-12/29/c_694559.htm">PIPL</Link>
        </div>
      </div>
      <div className="Footer_Copy">
        &copy; 2024 All rights reserved. Trademark copyright
      </div>
      {isShowTermsOfUseModal && (
        <ShowTermsOfUseModal
          showModal={isShowTermsOfUseModal}
          setShowModal={setIsShowTermsOfUseModal}
        />
      )}
    </footer>
  );
};
