import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes } from 'react';

import cn from 'classnames';

import { ReturnComponentType } from 'common';
import './CheckboxInput.scss';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
type RadioInputType = DefaultInputPropsType & {
  onChangeChecked?: (checked: boolean) => void;
  className?: string;
};
export const CheckboxInput = ({
  type,
  onChange,
  onChangeChecked,
  className,
  children,
  ...restProps
}: RadioInputType): ReturnComponentType => {
  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    if (onChangeChecked) onChangeChecked(e.currentTarget.checked);
  };

  return (
    <span className={cn('CheckboxInput', className)}>
      <input
        type="checkbox"
        onChange={onChangeCallback}
        className="CheckboxInput_Input"
        {...restProps}
      />
      {children && <span className="CheckboxInput_Child">{children}</span>}
    </span>
  );
};
