import React, { Suspense } from 'react';

import './App.scss';
import { AppRoutes } from './routes';

import { Loader } from 'components';

export const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Loader />
      <AppRoutes />
    </Suspense>
  );
};
