export enum CallStatus {
  STARTED = 'started',
  RINGING = 'ringing',
  ANSWERED = 'answered',
  COMPLETED = 'completed',
  BUSY = 'busy',
  TIMEOUT = 'timeout',
  UNANSWERED = 'unanswered',
  REJECTED = 'rejected',
  FAILED = 'failed',
}
