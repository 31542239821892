import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import cn from 'classnames';
import './Button.scss';

type ButtonPropsType = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  className?: string;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export const Button = ({
  className,
  children,
  isDisabled = false,
  onClick,
  ...rest
}: ButtonPropsType) => {
  return (
    <button
      type="button"
      className={cn('Button', className, {
        Button__disabled: isDisabled,
      })}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
