export const REQUIRED_FIELD = 'Required field';
export const WRONG_EMAIL = 'Invalid email or phone';
export const WRONG_PASSWORD = 'Password must contain from 8 to 16 characters';
export const PASSWORDS_NO_MATCH = "Your passwords don't match";
export const TERMS_OF_USE = 'Privacy Policy must be accepted';

export const EMAIL_CONDITION =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i;

export const PHONE_CONDITION = /^\+?[1-9]\d{1,14}$/;
export const emailValidation = {
  required: WRONG_EMAIL,
  validate: (value: string) => {
    if (!value.trim().match(EMAIL_CONDITION) && !value.trim().match(PHONE_CONDITION)) {
      return WRONG_EMAIL;
    }

    return true;
  },
};

export const commonValidation = {
  required: REQUIRED_FIELD,
};

export const createPasswordValidation = {
  required: REQUIRED_FIELD,
  validate: (value: string) => {
    if (value.length < 8 || value.length > 16) {
      return WRONG_PASSWORD;
    }

    return true;
  },
};
