import React from 'react';

import { TranscriptionType } from 'api/adminApi/types';
import { CommonModalType, ReturnComponentType } from 'common';
import { CommonModal } from 'components/Modals/CommonModal';
import { Button } from 'ui-kit';
import './ReadTranscriptionModal.scss';

type ReadTranscriptionModalType = CommonModalType & {
  transcriptions: TranscriptionType[];
};
export const ReadTranscriptionModal = ({
  showModal,
  setShowModal,
  transcriptions,
}: ReadTranscriptionModalType): ReturnComponentType => {
  const onCloseClick = () => {
    setShowModal(false);
  };

  return (
    <CommonModal showModal={showModal} closeModal={onCloseClick}>
      <section className="ReadTranscriptionModal">
        <h4>Transcription</h4>
        {transcriptions
          ?.sort((a, b) => +a.timestamp - +b.timestamp)
          ?.map((item, i) => {
            // eslint-disable-next-line react/no-array-index-key
            return <div key={i}>- {item.transcription}</div>;
          })}

        <Button onClick={onCloseClick} className="ReadTranscriptionModal_Button">
          Close
        </Button>
      </section>
    </CommonModal>
  );
};
