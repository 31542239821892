import { useEffect, useState } from 'react';

import NexmoClient from 'nexmo-client';

import { useAppSelector } from 'hooks/useAppSelector';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { getVonageToken, selectUser } from 'state';
import { setAuthLoading } from 'state/actions';

export const useInitNexmoClient = () => {
  const dispatch = useTypedDispatch();
  const [client, setClient] = useState<NexmoClient | undefined>(undefined);
  const user = useAppSelector(selectUser);

  const init = async () => {
    dispatch(setAuthLoading(true));
    try {
      const client = await new NexmoClient({
        // debug: true,
        nexmo_api_url: 'https://api-us-3.vonage.com',
        url: 'wss://ws-us-3.vonage.com',
      });

      setClient(client);
      dispatch(setAuthLoading(false));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.id) {
      dispatch(getVonageToken());
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    init();
  }, []);

  return client;
};
