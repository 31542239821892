import React, {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useState,
} from 'react';

import classNames from 'classnames';

import searchLogo from 'assets/images/search.svg';

import './SearchInput.scss';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type ISearchProps = DefaultInputPropsType & {
  className?: string;
  value?: string;
  onChangeText?: (value: string) => void;
  placeholder?: string;
};

export const SearchInput: FC<ISearchProps> = props => {
  const { className, onChangeText, onChange, value, placeholder } = props;
  const [isActive, setIsActive] = useState(false);

  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    if (onChangeText) onChangeText(e.currentTarget.value);
  };

  const handleFocus = () => {
    setIsActive(true);
  };
  const handleBlur = () => {
    setIsActive(false);
  };

  return (
    <div
      className={classNames('SearchInput', className, {
        SearchInput__active: isActive,
      })}
    >
      <div className="SearchInput_Form">
        <div className="SearchInput_InputWrapper">
          <input
            value={value}
            className="SearchInput_Input"
            type="text"
            onChange={onChangeCallback}
            onFocus={handleFocus}
            placeholder={placeholder}
            onBlur={handleBlur}
          />
          <img src={searchLogo} className="SearchInput_Icon" alt="search" />
        </div>
      </div>
    </div>
  );
};
