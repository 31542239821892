export enum Paths {
  MAIN = '/',
  LOGIN = '/login',
  RESTORE_PASSWORD = '/restore-password',
  SIGN_UP = '/sign-up',
  RESET_PASSWORD = '/reset-password',
  RESET_LINK = '/reset-link',
  SUCCESSFUL_NEW_PASSWORD = '/successful-new-password',
  ACTIVATED_LINK = '/activated-link',
  VERIFY = '/verify',
  ADMIN = '/admin',
  DASHBOARD = '/dashboard',
  USERS = '/users',
  CALLS = '/calls',
  ARTICLE = '/article',
  PRESS = '/press',
  SUBSCRIPTION = '/subscription',
  SOCIAL_SUCCESS = '/social-success',
  SUCCESS = '/success',
  CANCEL = '/cancel',
  RESEND_LINK = '/resend-link',
}
