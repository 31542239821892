import axios, { AxiosError } from 'axios';

import { authServiceClient } from 'config';
import { TypedDispatch, refreshToken, logOut } from 'state';
import { setError } from 'state/actions';

export const handleErrorServiceInterceptor = async (
  e: Error | AxiosError,
  dispatch: TypedDispatch,
) => {
  const errorMsg: string = extractErrorMessage(e);
  // @ts-ignore
  const originalConfig = e.config;

  // @ts-ignore
  if (originalConfig.url !== '/auth/sign-in' && e.response) {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if (e.response.status === 401 && !originalConfig._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalConfig._retry = true;
      try {
        const accessToken = await dispatch(refreshToken());

        originalConfig.headers.Authorization = `Bearer ${accessToken}`;

        return authServiceClient(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if (e.response.status === 401 && originalConfig._retry) {
      return dispatch(logOut());
    }
  }
  dispatch(setError(errorMsg));
};

export const extractErrorMessage = (e: Error | AxiosError | unknown): string => {
  if (axios.isCancel(e)) {
    return '';
  }

  if (axios.isAxiosError(e)) {
    const statusCode = e.response?.status;

    if (statusCode) {
      if (e.response?.data?.response?.message) {
        return e.response?.data?.response?.message;
      }
    }
  } else if (e instanceof Error && e?.message) {
    return e.message;
  }

  return 'Something went wrong';
};
