import callsLogo from 'assets/images/sideMenu/side-calls.svg';
import dashboardLogo from 'assets/images/sideMenu/side-dashboard.svg';
import pressLogo from 'assets/images/sideMenu/side-press.svg';
import usersLogo from 'assets/images/sideMenu/side-users.svg';
import { Paths } from 'enums';

export const MENU_ROUTES = [
  {
    path: `${Paths.ADMIN}${Paths.DASHBOARD}`,
    name: 'Dashboard',
    icon: dashboardLogo,
  },
  {
    path: `${Paths.ADMIN}${Paths.USERS}`,
    name: 'Users',
    icon: usersLogo,
  },
  {
    path: `${Paths.ADMIN}${Paths.CALLS}`,
    name: 'Calls',
    icon: callsLogo,
  },
  {
    path: `${Paths.ADMIN}${Paths.PRESS}`,
    name: 'Press',
    icon: pressLogo,
  },
];
