import { Roles } from 'enums';
import { AppRootStateType } from 'state/reducers';
import { GeolocationType, UserType } from 'state/reducers/auth/types';

export const selectAccessToken = (state: AppRootStateType): string =>
  state.auth.accessToken;
export const selectUser = (state: AppRootStateType): UserType => state.auth.user;
export const selectUserRole = (state: AppRootStateType): Roles => state.auth.user.role;
export const selectNotification = (state: AppRootStateType): string =>
  state.auth.notification;
export const selectError = (state: AppRootStateType): string | null => state.auth.error;
export const selectLoading = (state: AppRootStateType): boolean => state.auth.loading;
export const selectRememberMe = (state: AppRootStateType): boolean =>
  state.auth.rememberMe;
export const selectVonageToken = (state: AppRootStateType): string =>
  state.auth.vonageToken;
export const selectLanguageFrom = (state: AppRootStateType): string =>
  state.auth.languageFrom;
export const selectLanguageTo = (state: AppRootStateType): string =>
  state.auth.languageTo;
export const selectGeolocation = (state: AppRootStateType): GeolocationType =>
  state.auth.geolocation;
export const selectIsShowRulesMessage = (state: AppRootStateType): boolean =>
  state.auth.isShowRulesMessage;
export const selectPaypalToken = (state: AppRootStateType): string =>
  state.auth.paypalToken;
