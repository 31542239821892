export enum LsKeys {
  TOKEN_INFO = 'voice.token-info',
  LANGUAGE_FROM = 'voice.language-from',
  LANGUAGE_TO = 'voice.language-to',
  USERS_SETTINGS = 'voice.users-settings',
  CALLS_SETTINGS = 'voice.calls-settings',
  DASHBOARD_SETTINGS = 'voice.dashboard-settings',
  DASHBOARD_HEADER_SETTINGS = 'voice.dashboard-header-settings',
  SHOW_RULES_MESSAGE = 'voice.show-rules-message',
  ARTICLES_SETTINGS = 'voice.articles-settings',
}
