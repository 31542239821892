import React from 'react';

import { CommonModalType, ReturnComponentType } from 'common';
import { CommonModal } from 'components/Modals/CommonModal';
import { LsKeys } from 'enums';
import { useTypedDispatch } from 'hooks';
import { setShowRulesMessage } from 'state/actions';
import { Button } from 'ui-kit';
import { saveState } from 'utils';
import './ShowRulesModal.scss';

export const ShowRulesModal = ({
  showModal,
  setShowModal,
}: CommonModalType): ReturnComponentType => {
  const dispatch = useTypedDispatch();

  const onOkClick = () => {
    setShowModal(false);
    dispatch(setShowRulesMessage(true));
    saveState(LsKeys.SHOW_RULES_MESSAGE, true);
  };

  return (
    <CommonModal showModal={showModal} closeModal={onOkClick}>
      <section className="ShowRulesModal">
        <ul>
          Before you start to comfortably communicate with your interlocutor, please read
          some rules that will allow you to carry out communication in the most effective
          way:
          <li>
            Check that your internet connection is stable and use a VPN if you have
            difficulty initiating a call.
          </li>
          <li>
            If you are using the Safari browser to make calls, please check that Dictation
            is enabled on your device: Settings &gt; Basic &gt; Keyboard &gt; Dictation
            (enable).
          </li>
          <li>
            Speak in short sentences, then pause to allow the system to translate your
            speech to your interlocutor.
          </li>
          <li>Wait for the interlocutor&apos;s response and then speak your phrase. </li>
          <li>
            Try to pronounce all words clearly and loudly, in this case your interlocutor
            will receive the most correct translation.
          </li>
          <li>
            Speak in a quiet place, as background noises can negatively affect the quality
            of the translation.
          </li>
          <li>
            Use little slang and abbreviations so that your interlocutor understands your
            words correctly.
          </li>
          And enjoy communication without language barriers!
        </ul>

        <Button onClick={onOkClick} className="ShowRulesModal_Button">
          Ok
        </Button>
      </section>
    </CommonModal>
  );
};
