import { useEffect } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { selectError } from 'state';
import { setError } from 'state/actions';

export const useHandleError = (): string | null => {
  const dispatch = useTypedDispatch();
  const error = useAppSelector(selectError);

  useEffect(() => {
    if (error) {
      const timerId = setTimeout(() => {
        dispatch(setError(''));
        clearTimeout(timerId);
      }, 3000);
    }
  }, [dispatch, error]);

  return error;
};
