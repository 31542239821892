import { ISelectOption } from 'ui-kit/Select/Select';

export const getOptionForYearSelect = (startYear: number): ISelectOption[] => {
  const currentYear = new Date().getFullYear();
  const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)?.map(
      year => ({ value: year, label: year }),
    );

  return range(currentYear, startYear, -1);
};
