export function saveStateToSs<T>(key: string, state: T) {
  const stateAsString = JSON.stringify(state);

  sessionStorage.setItem(key, stateAsString);
}

export function restoreStateFromSs(key: string) {
  const stateAsString = sessionStorage.getItem(key);

  if (stateAsString !== undefined && stateAsString !== null)
    return JSON.parse(stateAsString);

  return null;
}
