export function saveState<T>(key: string, state: T) {
  const stateAsString = JSON.stringify(state);

  localStorage.setItem(key, stateAsString);
}

export function restoreState(key: string) {
  const stateAsString = localStorage.getItem(key);

  if (stateAsString !== undefined && stateAsString !== null)
    return JSON.parse(stateAsString);

  return null;
}

export const checkLS = (namePage: string, value: any) => {
  const settings: string | null = localStorage.getItem(namePage);

  if (settings) {
    const allSettings = JSON.parse(settings);

    localStorage.setItem(namePage, JSON.stringify({ ...allSettings, ...value }));
  } else {
    localStorage.setItem(namePage, JSON.stringify(value));
  }
};

export const getSettingsFromLS = (
  key: string,
  namePage: string,
  defaultValue: number | string | undefined | null | boolean,
) => {
  const localSettings: string | null = localStorage.getItem(namePage);

  if (localSettings) {
    const settings = JSON.parse(localSettings);

    if (settings) {
      if (Object.keys(settings).includes(key)) {
        return settings[key];
      }
    }
  }

  return defaultValue;
};
