import { CountryCodes } from 'enums';

export const returnLanguage = (language: string): string => {
  return CountryCodes.find(item => item.vonageCode === language)?.vonageCode ?? 'en-US';
};
export const returnStyle = (language: string): number => {
  return CountryCodes.find(item => item.vonageCode === language)?.style ?? 0;
};
export const returnAzureLanguage = (language: string): string => {
  return CountryCodes.find(item => item.vonageCode === language)?.azureCode ?? 'en';
};
