import { AxiosResponse } from 'axios';

import {
  ArticleType,
  CallType,
  CreateArticleRequestType,
  GetCallStatisticRequestType,
} from 'api/adminApi/types';
import { CommonResponseType } from 'api/authApi';
import { GeneralRequestType, GeneralResponseType } from 'common';
import { authServiceClient } from 'config';
import { UserType } from 'state';
import {
  CallsStatisticType,
  StatisticType,
  UsersSettingsType,
} from 'state/reducers/admin/types';

export const getStatisticRequest = (params: { month: number; year: number }) => {
  return authServiceClient.get<
    StatisticType,
    AxiosResponse<StatisticType>,
    { country: number; year: number }
  >(`/user/statistic`, { params });
};

export const getCallsRequest = (
  params: GeneralRequestType & { asc?: 'asc' | 'desc' },
) => {
  return authServiceClient.get<
    GeneralResponseType<CallType>,
    AxiosResponse<GeneralResponseType<CallType>>,
    GeneralRequestType
  >(`/call`, {
    params,
  });
};

export const getUsersRequest = (params: UsersSettingsType & { take: number }) => {
  return authServiceClient.get<
    GeneralResponseType<UserType>,
    AxiosResponse<GeneralResponseType<UserType>>,
    UsersSettingsType & { take: number }
  >(`/user`, { params });
};

export const changeUserStatusRequest = (id: string) => {
  return authServiceClient.patch<CommonResponseType, AxiosResponse<CommonResponseType>>(
    `/user/change-block-status/${id}`,
  );
};

export const deleteUsersRequest = (ids: string[]) => {
  return authServiceClient.delete<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    { ids: string[] }
  >(`/user/bulk`, { data: { ids } });
};

export const getRecordRequest = (id: string) => {
  return authServiceClient.get<any, AxiosResponse<any>>(`/vonage/record/${id}`);
};

export const getCallStatisticRequest = (params: GetCallStatisticRequestType) => {
  return authServiceClient.get<
    CallsStatisticType,
    AxiosResponse<CallsStatisticType>,
    GetCallStatisticRequestType
  >(`/call/statistic`, { params });
};

export const getCountryListRequest = () => {
  return authServiceClient.get<string[], AxiosResponse<string[]>>(`/call/countries`);
};

export const deleteCallsRequest = (ids: string[]) => {
  return authServiceClient.delete<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    { ids: string[] }
  >(`/call/bulk`, { data: { ids } });
};

export const createArticleRequest = (data: CreateArticleRequestType) => {
  return authServiceClient.post<
    ArticleType,
    AxiosResponse<ArticleType>,
    CreateArticleRequestType
  >(`/article`, data);
};

export const getArticlesRequest = (params: { page: number; take: number }) => {
  return authServiceClient.get<
    GeneralResponseType<ArticleType>,
    AxiosResponse<GeneralResponseType<ArticleType>>,
    { page: number; take: number }
  >(`/article`, { params });
};

export const deleteArticleRequest = (id: string) => {
  return authServiceClient.delete<CommonResponseType, AxiosResponse<CommonResponseType>>(
    `/article/${id}`,
  );
};

export const changeArticleRequest = (id: string, data: CreateArticleRequestType) => {
  return authServiceClient.put<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    CreateArticleRequestType
  >(`/article/${id}`, data);
};
