import React from 'react';

import cn from 'classnames';
import './ThreeDots.scss';

import { ReturnComponentType } from 'common';

type ThreeDotsType = {
  onClick?: () => void;
  className?: string;
};

export const ThreeDots = ({ onClick, className }: ThreeDotsType): ReturnComponentType => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={cn('Dots', className)} onClick={onClick}>
      <div className="Dots_Dot" />
      <div className="Dots_Dot" />
      <div className="Dots_Dot" />
    </div>
  );
};
