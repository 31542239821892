import React, { ReactNode } from 'react';

import cn from 'classnames';

import logo from 'assets/images/big-logo.svg';
import { ReturnComponentType } from 'common';
import './ExternalHeader.scss';
import { useHandleError } from 'hooks';

type ExternalHeaderType = {
  children: ReactNode;
  className?: string;
  isShowLogo?: boolean;
};
export const ExternalHeader = ({
  children,
  className,
  isShowLogo = true,
}: ExternalHeaderType): ReturnComponentType => {
  const error = useHandleError();

  return (
    <div className={cn('ExternalHeader', className)}>
      {isShowLogo && <img src={logo} alt="logo" className="ExternalHeader_Logo" />}
      {error && <div className="ExternalHeader_Error">{error}</div>}
      <div className="ExternalHeader_Child">{children}</div>
    </div>
  );
};
