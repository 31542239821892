import { Months } from 'enums';
import { CallsStatisticType } from 'state/reducers/admin/types';
import { toCapitalize } from 'utils/toCapitalize';

export const getDataForChart = (
  callsStatistic: CallsStatisticType,
): { name: string; calls: number; subscriptions: number }[] => {
  return Months.map(month => ({
    name: toCapitalize(month.slice(0, 3)),
    calls: callsStatistic?.calls?.find(call => call?.month === month)?.count ?? 0,
    subscriptions:
      callsStatistic?.subscribers?.find(sub => sub?.month === month)?.count ?? 0,
  }));
};
