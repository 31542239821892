import { AuthActionType } from 'enums';
import { GeolocationType } from 'state/reducers/auth/types';

export const setAuthLoading = (loading: boolean) =>
  ({ type: AuthActionType.LOADING, loading } as const);

export const setLogIn = (data: { accessToken: string; refreshToken: string }) =>
  ({ type: AuthActionType.LOG_IN, data } as const);

export const setLogOut = () => ({ type: AuthActionType.LOG_OUT } as const);
export const setError = (message: string | null) =>
  ({ type: AuthActionType.ERROR, message } as const);

export const setUserInfo = (user: any) =>
  ({ type: AuthActionType.GET_USER_INFO, user } as const);

export const changePasswordAction = (isChangePassword: boolean) =>
  ({ type: AuthActionType.CHANGE_PASSWORD, isChangePassword } as const);

export const setNotification = (notification: string) =>
  ({ type: AuthActionType.SET_NOTIFICATION, notification } as const);

export const setRememberMe = (rememberMe: boolean) =>
  ({ type: AuthActionType.SET_REMEMBER_ME, rememberMe } as const);

export const setVonageToken = (token: string) =>
  ({ type: AuthActionType.SET_VONAGE_TOKEN, token } as const);

export const setLanguageFrom = (language: string) =>
  ({ type: AuthActionType.SET_LANGUAGE_FROM, language } as const);

export const setLanguageTo = (language: string) =>
  ({ type: AuthActionType.SET_LANGUAGE_TO, language } as const);

export const setGeolocation = (geolocation: GeolocationType) =>
  ({ type: AuthActionType.SET_GEOLOCATION, geolocation } as const);

export const setShowRulesMessage = (isShowRulesMessage: boolean) =>
  ({ type: AuthActionType.SET_SHOW_RULES_MESSAGE, isShowRulesMessage } as const);

export const setPaypalToken = (token: string) =>
  ({ type: AuthActionType.SET_PAYPAL_TOKEN, token } as const);
