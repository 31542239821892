import React, { ReactNode } from 'react';

import logo from 'assets/images/logo.svg';
import { ReturnComponentType } from 'common';
import './ExternalBaseComponent.scss';
import { Footer } from 'components/Footer';

type ExternalBaseComponentType = {
  children: ReactNode;
};
export const ExternalBaseComponent = ({
  children,
}: ExternalBaseComponentType): ReturnComponentType => {
  return (
    <div className="ExternalBaseComponent">
      <section className="ExternalBaseComponent_Content">
        <div className="ExternalBaseComponent_Content_Logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="ExternalBaseComponent_Content_Child">{children}</div>
      </section>
      <Footer />
    </div>
  );
};
