import { useEffect, useState } from 'react';

import { ResponseEventType } from 'common';
import { ApiPath } from 'enums';
import { useAppSelector } from 'hooks/useAppSelector';
import { selectActiveCall, selectUser } from 'state';

export const useDataFromRecipient = () => {
  const isActiveCall = useAppSelector(selectActiveCall);
  const user = useAppSelector(selectUser);
  const [dataFromRecipient, setDataFromRecipient] = useState<ResponseEventType[]>([]);

  useEffect(() => {
    if (isActiveCall && user?.id) {
      const translationSse = new EventSource(
        `${ApiPath.auth}/vonage/sse/translation/${user?.id}`,
      );

      translationSse.addEventListener(`call.new-translation`, e =>
        setDataFromRecipient(JSON.parse(e.data)),
      );
      translationSse.onerror = e => {
        translationSse.close();
      };

      return () => {
        translationSse.close();
      };
    }
  }, [isActiveCall, user?.id]);

  return dataFromRecipient;
};
