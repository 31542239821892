import React, { useEffect } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { ReturnComponentType } from 'common';
import { Paths } from 'enums';
import { useAppSelector, useTypedDispatch } from 'hooks';
import { getMe, selectAccessToken } from 'state';

export const ProtectedRoute = ({ children }: any): ReturnComponentType => {
  const location = useLocation();
  const token = useAppSelector(selectAccessToken);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getMe(token));
    }
  }, [dispatch]);

  return token ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Navigate to={Paths.LOGIN} replace state={location} />
  );
};
