import { AuthStateType, GeolocationType, UserType } from './types';

import { AuthActionType, CountryCodes, LsKeys, SsKeys } from 'enums';
import { AuthActionReturnType } from 'state/actions';
import { restoreState, restoreStateFromSs } from 'utils';

const initState: AuthStateType = {
  loading: false,
  error: null,
  accessToken:
    restoreState(LsKeys.TOKEN_INFO)?.accessToken ??
    restoreStateFromSs(SsKeys.TOKEN_INFO)?.accessToken ??
    '',
  refreshToken:
    restoreState(LsKeys.TOKEN_INFO)?.refreshToken ??
    restoreStateFromSs(SsKeys.TOKEN_INFO)?.refreshToken ??
    '',
  notification: '',
  user: {} as UserType,
  rememberMe: !!restoreState(LsKeys.TOKEN_INFO)?.accessToken ?? false,
  vonageToken: '',
  languageFrom: restoreState(LsKeys.LANGUAGE_FROM) ?? CountryCodes[0].vonageCode,
  languageTo: restoreState(LsKeys.LANGUAGE_TO) ?? CountryCodes[0].vonageCode,
  geolocation: {} as GeolocationType,
  isShowRulesMessage: restoreState(LsKeys.SHOW_RULES_MESSAGE) ?? false,
  paypalToken: '',
};

export const authReducer = (
  // eslint-disable-next-line default-param-last
  state: AuthStateType = initState,
  action: AuthActionReturnType,
): AuthStateType => {
  switch (action.type) {
    case AuthActionType.LOADING:
      return { ...state, loading: action.loading };
    case AuthActionType.LOG_IN:
      return {
        ...state,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
        error: null,
      };
    case AuthActionType.ERROR:
      return { ...state, error: action.message };
    case AuthActionType.SET_NOTIFICATION:
      return { ...state, notification: action.notification };
    case AuthActionType.LOG_OUT:
      return {
        ...state,
        accessToken: '',
        refreshToken: '',
        user: {} as UserType,
        isShowRulesMessage: false,
      };
    case AuthActionType.GET_USER_INFO:
      return { ...state, user: action.user };
    case AuthActionType.SET_REMEMBER_ME:
      return { ...state, rememberMe: action.rememberMe };
    case AuthActionType.SET_VONAGE_TOKEN:
      return { ...state, vonageToken: action.token };
    case AuthActionType.SET_LANGUAGE_FROM:
      return { ...state, languageFrom: action.language };
    case AuthActionType.SET_LANGUAGE_TO:
      return { ...state, languageTo: action.language };
    case AuthActionType.SET_GEOLOCATION:
      return { ...state, geolocation: action.geolocation };
    case AuthActionType.SET_SHOW_RULES_MESSAGE:
      return { ...state, isShowRulesMessage: action.isShowRulesMessage };
    case AuthActionType.SET_PAYPAL_TOKEN:
      return { ...state, paypalToken: action.token };
    default:
      return state;
  }
};
