import React, {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  KeyboardEvent,
  useId,
  useMemo,
  useState,
} from 'react';

import cn from 'classnames';

import eye from 'assets/images/eye-hide.svg';

import './InputForm.scss';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type InputFormProps = DefaultInputPropsType & {
  onChangeText?: (value: string) => void;
  onEnter?: () => void;
  error?: string;
  spanClassName?: string;
  label?: string;
};

export const InputForm: React.FC<InputFormProps> = props => {
  const {
    type,
    onChange,
    onChangeText,
    onKeyDown,
    onEnter,
    error,
    className,
    spanClassName,
    label,
    ...rest
  } = props;
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const id = useId();

  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    if (onChangeText) onChangeText(e.currentTarget.value);
  };
  const onKeyPressCallback = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(e);

    if (onEnter) {
      if (e.key === 'Enter') onEnter();
    }
  };
  const finalClassname = error ? 'InputForm__Input InputForm__Error' : 'InputForm__Input';

  const conditionShowPassword = useMemo(() => {
    if (type === 'password' && isShowPassword) return 'text';

    return 'password';
  }, [isShowPassword]);
  const onChangeShowPassword = () => {
    setIsShowPassword(prev => !prev);
  };

  return (
    <div className={cn('InputForm', className)}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {label && (
        <label className="InputForm__Label" htmlFor={`${id}-input`}>
          {label}
        </label>
      )}
      {type === 'password' && (
        <div className="InputForm__Icon">
          <img
            src={eye}
            alt="show-password"
            onClick={onChangeShowPassword}
            // className="InputForm__Icon"
          />
          {isShowPassword ? 'Hide' : 'Show'}
        </div>
      )}
      <input
        type={type === 'password' ? conditionShowPassword : type}
        onChange={onChangeCallback}
        onKeyDown={onKeyPressCallback}
        className={finalClassname}
        id={`${id}-input`}
        aria-describedby={`${id}-error-message`}
        {...rest}
      />
      {error && (
        <span aria-live="polite" className="TextError" id={`${id}-error-message`}>
          {error}
        </span>
      )}
    </div>
  );
};
