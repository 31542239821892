import React, { useCallback } from 'react';

import cn from 'classnames';

import { ReturnComponentType } from 'common';
import { CountryCodes, LsKeys, Purpose, SubscriptionType } from 'enums';
import { useAppSelector, useTypedDispatch } from 'hooks';
import { selectLanguageFrom, selectLanguageTo, selectUser } from 'state';
import { setLanguageFrom, setLanguageTo } from 'state/actions';
import { Select } from 'ui-kit';
import { ISelectOption } from 'ui-kit/Select/Select';
import './SelectLanguage.scss';
import { saveState, splitByUpperCase } from 'utils';

const languageOptions = CountryCodes.map(item => {
  return { value: item.vonageCode, label: item.title };
});

type SelectLanguageType = {
  purpose: Purpose;
  disabled?: boolean;
  className?: string;
};

export const SelectLanguage = ({
  purpose,
  className,
  disabled = false,
}: SelectLanguageType): ReturnComponentType => {
  const dispatch = useTypedDispatch();
  const user = useAppSelector(selectUser);
  const languageFrom = useAppSelector(selectLanguageFrom);
  const languageTo = useAppSelector(selectLanguageTo);

  const onChangeLanguage = (language: any) => {
    if (purpose === Purpose.FROM) {
      dispatch(setLanguageFrom(language.value));
      saveState(LsKeys.LANGUAGE_FROM, language.value);
    }
    if (purpose === Purpose.TO) {
      dispatch(setLanguageTo(language.value));
      saveState(LsKeys.LANGUAGE_TO, language.value);
    }
  };
  const formatOptionLabel = useCallback((item: ISelectOption) => {
    return (
      <div className="Row">
        <span>{splitByUpperCase(`${item?.label}`)}</span>

        {item?.image ? <img src={item?.image} alt="flag-icon" /> : null}
      </div>
    );
  }, []);

  return (
    <Select
      className={cn('SelectLanguage', className)}
      options={
        user?.subscriptionType === SubscriptionType.STANDARD
          ? languageOptions?.slice(0, 20)
          : languageOptions
      }
      value={languageOptions.find(
        lang => lang.value === (purpose === Purpose.FROM ? languageFrom : languageTo),
      )}
      formatOptionLabel={formatOptionLabel}
      onChange={language => onChangeLanguage(language)}
      isOptionDisabled={disabled}
    />
  );
};
