import { CountryCodeType } from 'common';

export const CountryCodes: CountryCodeType[] = [
  {
    title: 'English (United States)',
    vonageCode: 'en-US',
    googleCode: 'en-US',
    azureCode: 'en',
    style: 9,
  },
  {
    title: 'Norwegian',
    vonageCode: 'no-NO',
    googleCode: 'no-NO',
    azureCode: 'no',
    style: 1,
  },
  {
    title: 'French',
    vonageCode: 'fr-FR',
    googleCode: 'fr-FR',
    azureCode: 'fr',
    style: 5,
  },
  {
    title: 'German',
    vonageCode: 'de-DE',
    googleCode: 'de-DE',
    azureCode: 'de',
    style: 3,
  },
  {
    title: 'Italian',
    vonageCode: 'it-IT',
    googleCode: 'it-IT',
    azureCode: 'it',
    style: 4,
  },
  {
    title: 'Arabic',
    vonageCode: 'ar',
    googleCode: 'ar-DZ',
    azureCode: 'ar',
    style: 6,
  },
  {
    title: 'Russian',
    vonageCode: 'ru-RU',
    googleCode: 'ru-RU',
    azureCode: 'ru',
    style: 4,
  },
  {
    title: 'Spanish',
    vonageCode: 'es-ES',
    googleCode: 'es-ES',
    azureCode: 'es',
    style: 3,
  },
  {
    title: 'Portuguese',
    vonageCode: 'pt-PT',
    googleCode: 'pt-PT',
    azureCode: 'pt',
    style: 4,
  },
  {
    title: 'Chinese, Mandarin (Taiwan)',
    vonageCode: 'cmn-TW',
    googleCode: 'zh-TW',
    azureCode: 'zh',
    style: 0,
  },
  /*  {
    title: 'Chinese, Mandarin',
    vonageCode: 'cmn-CN',
    googleCode: 'zh',
    azureCode: 'zh-Hans',
    style: 0,
  }, */
  /*  {
    title: 'Welsh (United Kingdom)',
    vonageCode: 'cy-GB',
    googleCode: 'en-GB',
    azureCode: 'en',
    style: 0,
  }, */
  {
    title: 'Catalan',
    vonageCode: 'ca-ES',
    googleCode: 'ca-ES',
    azureCode: 'ca',
    style: 0,
  },

  { title: 'Czech', vonageCode: 'cs-CZ', googleCode: 'cs-CZ', azureCode: 'cs', style: 2 },
  {
    title: 'Danish',
    vonageCode: 'da-DK',
    googleCode: 'da-DK',
    azureCode: 'da',
    style: 2,
  },
  { title: 'Greek', vonageCode: 'el-GR', googleCode: 'el-GR', azureCode: 'el', style: 1 },
  /*  {
    title: 'English (Australia)',
    vonageCode: 'en-AU',
    googleCode: 'en-AU',
    azureCode: 'en',
    style: 3,
  }, */
  {
    title: 'English (United Kingdom)',
    vonageCode: 'en-GB',
    googleCode: 'en-GB',
    azureCode: 'en',
    style: 0,
  },
  /*  {
    title: 'Welsh',
    vonageCode: 'en-GB-WLS',
    googleCode: 'en-GB',
    azureCode: 'en',
    style: 0,
  }, */
  /*  {
    title: 'English (India)',
    vonageCode: 'en-IN',
    googleCode: 'en-IN',
    azureCode: 'en',
    style: 1,
  }, */
  /*  {
    title: 'English (South Africa)',
    vonageCode: 'en-ZA',
    googleCode: 'en-ZA',
    azureCode: 'en',
    style: 0,
  }, */
  /*  {
    title: 'English (Spain)',
    vonageCode: 'en-ES',
    googleCode: 'en-US',
    azureCode: 'en',
    style: 0,
  }, */
  {
    title: 'Spanish (Mexico)',
    vonageCode: 'es-MX',
    googleCode: 'es-MX',
    azureCode: 'es',
    style: 0,
  },
  {
    title: 'Spanish (United States)',
    vonageCode: 'es-US',
    googleCode: 'es-US',
    azureCode: 'es',
    style: 1,
  },
  {
    title: 'Basque (Spain)',
    vonageCode: 'eu-ES',
    googleCode: 'eu-ES',
    azureCode: 'eu',
    style: 0,
  },
  {
    title: 'Finnish (Finland)',
    vonageCode: 'fi-FI',
    googleCode: 'fi-FI',
    azureCode: 'fi',
    style: 1,
  },
  {
    title: 'Filipino (Philippines)',
    vonageCode: 'fil-PH',
    googleCode: 'fil-PH',
    azureCode: 'fil',
    style: 0,
  },
  {
    title: 'French (Canada)',
    vonageCode: 'fr-CA',
    googleCode: 'fr-CA',
    azureCode: 'fr',
    style: 0,
  },
  {
    title: 'Hebrew',
    vonageCode: 'he-IL',
    googleCode: 'iw-IL',
    azureCode: 'he',
    style: 0,
  },
  { title: 'Hindi', vonageCode: 'hi-IN', googleCode: 'hi-IN', azureCode: 'hi', style: 2 },
  {
    title: 'Hungarian',
    vonageCode: 'hu-HU',
    googleCode: 'hu-HU',
    azureCode: 'hu',
    style: 1,
  },
  {
    title: 'Indonesian',
    vonageCode: 'id-ID',
    googleCode: 'id-ID',
    azureCode: 'id',
    style: 1,
  },
  {
    title: 'Icelandic',
    vonageCode: 'is-IS',
    googleCode: 'is-IS',
    azureCode: 'is',
    style: 1,
  },
  {
    title: 'Japanese',
    vonageCode: 'ja-JP',
    googleCode: 'ja-JP',
    azureCode: 'ja',
    style: 3,
  },
  {
    title: 'Korean',
    vonageCode: 'ko-KR',
    googleCode: 'ko-KR',
    azureCode: 'ko',
    style: 3,
  },
  {
    title: 'Norwegian Bokmål',
    vonageCode: 'nb-NO',
    googleCode: 'no-NO',
    azureCode: 'nb',
    style: 0,
  },
  { title: 'Dutch', vonageCode: 'nl-NL', googleCode: 'nl-NL', azureCode: 'nl', style: 0 },
  {
    title: 'Polish',
    vonageCode: 'pl-PL',
    googleCode: 'pl-PL',
    azureCode: 'pl',
    style: 5,
  },
  {
    title: 'Portuguese (Brazil)',
    vonageCode: 'pt-BR',
    googleCode: 'pt-BR',
    azureCode: 'pt',
    style: 2,
  },
  {
    title: 'Romanian',
    vonageCode: 'ro-RO',
    googleCode: 'ro-RO',
    azureCode: 'ro',
    style: 1,
  },
  {
    title: 'Slovak',
    vonageCode: 'sk-SK',
    googleCode: 'sk-SK',
    azureCode: 'sk',
    style: 1,
  },
  {
    title: 'Swedish',
    vonageCode: 'sv-SE',
    googleCode: 'sv-SE',
    azureCode: 'sv',
    style: 3,
  },
  { title: 'Thai', vonageCode: 'th-TH', googleCode: 'th-TH', azureCode: 'th', style: 0 },
  {
    title: 'Turkish',
    vonageCode: 'tr-TR',
    googleCode: 'tr-TR',
    azureCode: 'tr',
    style: 4,
  },
  {
    title: 'Ukrainian',
    vonageCode: 'uk-UA',
    googleCode: 'uk-UA',
    azureCode: 'uk',
    style: 0,
  },
  {
    title: 'Vietnamese',
    vonageCode: 'vi-VN',
    googleCode: 'vi-VN',
    azureCode: 'vi',
    style: 0,
  },
  {
    title: 'Chinese, Cantonese',
    vonageCode: 'yue-CN',
    googleCode: 'yue-Hant-HK',
    azureCode: 'zh',
    style: 0,
  },
];
