import React from 'react';

import { useNavigate } from 'react-router';

import { ReturnComponentType } from 'common';
import { CommonModal } from 'components/Modals/CommonModal';
import { Paths } from 'enums';
import { useAppSelector, useTypedDispatch } from 'hooks';
import { selectCallTimeOver } from 'state';
import { setCallTimeOver } from 'state/actions';
import { Button } from 'ui-kit';

import './GetSubscriptionModal.scss';

export const GetSubscriptionModal = (): ReturnComponentType => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const isCallTimeOver = useAppSelector(selectCallTimeOver);
  const onYesClick = () => {
    navigate(Paths.SUBSCRIPTION);
  };

  const closeModal = () => {
    dispatch(setCallTimeOver(false));
  };

  return (
    <CommonModal showModal={isCallTimeOver} closeModal={closeModal}>
      <section className="GetSubscriptionModal">
        <h4>Get Subscription</h4>
        <span>
          Unfortunately, you can no longer make calls because you have exhausted your free
          3 minutes. But you can subscribe and get access to unlimited calls through the
          app.
          <br />
          Would you like to subscribe?
        </span>
        <div className="GetSubscriptionModal_ButtonsGroup">
          <Button
            onClick={closeModal}
            className="GetSubscriptionModal_ButtonsGroup_Button"
          >
            No
          </Button>
          <Button
            onClick={onYesClick}
            className="GetSubscriptionModal_ButtonsGroup_Button"
          >
            Yes
          </Button>
        </div>
      </section>
    </CommonModal>
  );
};
