import React, { useEffect, useState } from 'react';

import dropin, { Dropin } from 'braintree-web-drop-in';
import { useNavigate } from 'react-router';

import { CommonModalType } from 'common';
import { CommonModal } from 'components/Modals/CommonModal';
import { Paths, SubscriptionType } from 'enums';
import { useAppSelector, useTypedDispatch } from 'hooks';
import { addPaymentMethodNonce, selectPaypalToken } from 'state';
import { Button } from 'ui-kit';
import './BraintreeDropInModal.scss';

type BraintreeDropInModalType = CommonModalType & {
  price: number;
  title: SubscriptionType;
};
export const BraintreeDropInModal = ({
  showModal,
  setShowModal,
  price,
  title,
}: BraintreeDropInModalType) => {
  const paypalToken = useAppSelector(selectPaypalToken);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [braintreeInstance, setBraintreeInstance] = useState<Dropin | undefined>(
    undefined,
  );

  const onCloseClick = () => {
    setShowModal(false);
  };

  const onPayClick = () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod(async (error, payload) => {
        if (error) {
          console.error(error);
        } else {
          const success = await dispatch(
            addPaymentMethodNonce(payload.nonce, `${price}`, title),
          );

          if (success) {
            navigate(Paths.SUCCESS);
          } else navigate(Paths.CANCEL);
        }
      });
    }
  };

  useEffect(() => {
    if (showModal && paypalToken) {
      const initializeBraintree = () =>
        dropin.create(
          {
            authorization: paypalToken,
            container: '#braintree-drop-in-div',
          },
          (error, instance) => {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          },
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [showModal, paypalToken]);

  return (
    <CommonModal showModal={showModal} closeModal={onCloseClick}>
      <div className="BraintreeDropInModal">
        <div id="braintree-drop-in-div" />

        <Button
          className="BraintreeDropInModal_Button"
          disabled={!braintreeInstance}
          onClick={onPayClick}
        >
          Pay
        </Button>
      </div>
    </CommonModal>
  );
};
