export enum AuthActionType {
  LOG_IN = 'LOG_IN',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  LOG_OUT = 'LOG_OUT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  GET_USER_INFO = 'GET_USER_INFO',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  SET_REMEMBER_ME = 'SET_REMEMBER_ME',
  SET_VONAGE_TOKEN = 'SET_VONAGE_TOKEN',
  SET_LANGUAGE_FROM = 'SET_LANGUAGE_FROM',
  SET_LANGUAGE_TO = 'SET_LANGUAGE_TO',
  SET_GEOLOCATION = 'SET_GEOLOCATION',
  SET_SHOW_RULES_MESSAGE = 'SET_SHOW_RULES_MESSAGE',
  SET_PAYPAL_TOKEN = 'SET_PAYPAL_TOKEN',
}
