import React, { ReactNode } from 'react';

import cn from 'classnames';

import { ReturnComponentType } from 'common';

import './ExternalDataCard.scss';

type ExternalDataCardType = {
  children: ReactNode;
  className?: string;
};
export const ExternalDataCard = ({
  children,
  className,
}: ExternalDataCardType): ReturnComponentType => {
  return <section className={cn('ExternalDataCard', className)}>{children}</section>;
};
