import { ArticleType, CallType } from 'api';
import { GeneralSettingsType } from 'common';
import { AdminActionType } from 'enums';
import {
  CallsStatisticType,
  DashboardHeaderSettingsType,
  DashboardSettingsType,
  StatisticType,
  UsersSettingsType,
} from 'state/reducers/admin/types';
import { UserType } from 'state/reducers/auth/types';

export const setStatisticAction = (statistic: StatisticType) =>
  ({ type: AdminActionType.SET_STATISTIC, statistic } as const);

export const setCallsAction = (calls: CallType[]) =>
  ({ type: AdminActionType.SET_CALLS, calls } as const);

export const setUsersAction = (users: UserType[]) =>
  ({ type: AdminActionType.SET_USERS, users } as const);

export const setUsersSettingsAction = (usersSettings: UsersSettingsType) =>
  ({ type: AdminActionType.SET_USERS_SETTINGS, usersSettings } as const);

export const setCallsSettingsAction = (
  callsSettings: GeneralSettingsType & { asc?: 'asc' | 'desc' },
) => ({ type: AdminActionType.SET_CALLS_SETTINGS, callsSettings } as const);

export const setDashboardSettingsAction = (dashboardSettings: DashboardSettingsType) =>
  ({ type: AdminActionType.SET_DASHBOARD_SETTINGS, dashboardSettings } as const);

export const setUsersCountAction = (usersCount: number) =>
  ({ type: AdminActionType.SET_USERS_COUNT, usersCount } as const);

export const setCallsCountAction = (callsCount: number) =>
  ({ type: AdminActionType.SET_CALLS_COUNT, callsCount } as const);

export const setCountryListAction = (countryList: string[]) =>
  ({ type: AdminActionType.SET_COUNTRY_LIST, countryList } as const);

export const setCallsStatisticAction = (callsStatistic: CallsStatisticType) =>
  ({ type: AdminActionType.SET_CALLS_STATISTIC, callsStatistic } as const);

export const setDashboardHeaderSettingsAction = (
  dashboardHeaderSettings: DashboardHeaderSettingsType,
) =>
  ({
    type: AdminActionType.SET_DASHBOARD_HEADER_SETTINGS,
    dashboardHeaderSettings,
  } as const);

export const setArticlesAction = (articles: ArticleType[]) =>
  ({ type: AdminActionType.SET_ARTICLES, articles } as const);

export const setArticlesCountAction = (articlesCount: number) =>
  ({ type: AdminActionType.SET_ARTICLES_COUNT, articlesCount } as const);

export const setArticlesSettingsAction = (articlesSettings: { page: number }) =>
  ({ type: AdminActionType.SET_ARTICLES_SETTINGS, articlesSettings } as const);
