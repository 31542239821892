import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { rootReducer } from 'state/reducers';

const configureStore = () => {
  const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // @ts-ignore
  return createStore(rootReducer, composeEnhancer(applyMiddleware(thunkMiddleware)));
};

export const store = configureStore();
