export enum AdminActionType {
  SET_STATISTIC = 'SET_STATISTIC',
  SET_CALLS = 'SET_CALLS',
  SET_USERS = 'SET_USERS',
  SET_USERS_SETTINGS = 'SET_USERS_SETTINGS',
  SET_CALLS_SETTINGS = 'SET_CALLS_SETTINGS',
  SET_DASHBOARD_SETTINGS = 'SET_DASHBOARD_SETTINGS',
  SET_USERS_COUNT = 'SET_USERS_COUNT',
  SET_CALLS_COUNT = 'SET_CALLS_COUNT',
  SET_COUNTRY_LIST = 'SET_COUNTRY_LIST',
  SET_CALLS_STATISTIC = 'SET_CALLS_STATISTIC',
  SET_DASHBOARD_HEADER_SETTINGS = 'SET_DASHBOARD_HEADER_SETTINGS',
  SET_ARTICLES = 'SET_ARTICLES',
  SET_ARTICLES_COUNT = 'SET_ARTICLES_COUNT',
  SET_ARTICLES_SETTINGS = 'SET_ARTICLES_SETTINGS',
}
