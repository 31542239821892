import axios, { AxiosResponse } from 'axios';

import {
  LoginResponseType,
  NewPasswordRequestType,
  SignUpRequestType,
  CommonResponseType,
  LoginRequestType,
  TranslationRequestType,
  TranslationResponseType,
  InviteMemberRequestType,
  UserLoginResponseType,
  StripeProductType,
  CheckoutSessionType,
  AddTranscriptionRequestType,
  AddPaymentMethodNonceRequestType,
  GeneratePaypalTokenResponseType,
} from './types';

import { authServiceClient } from 'config';
import { SubscriptionType } from 'enums';

export const logInRequest = (data: LoginRequestType) => {
  return authServiceClient.post<
    LoginResponseType,
    AxiosResponse<LoginResponseType>,
    LoginRequestType
  >(`/auth/sign-in`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const ipRequest = () => {
  return axios.get(`https://ipapi.co/json`);
};

export const signUpRequest = (data: SignUpRequestType) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    SignUpRequestType
  >(`/auth/sign-up`, data);
};

export const sendCodeRequest = (token: string) => {
  return authServiceClient.get<
    LoginResponseType,
    AxiosResponse<LoginResponseType>,
    { token: string }
  >(`/auth/verify?token=${token}`);
};

export const restorePasswordRequest = (data: Omit<LoginRequestType, 'password'>) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    Omit<LoginRequestType, 'password'>
  >(`/auth/forgot-password`, data);
};

export const changePassword = ({
  confirmPassword,
  password,
  token,
}: NewPasswordRequestType) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    Omit<NewPasswordRequestType, 'token'>
  >(`/auth/reset-password?token=${token}`, {
    password,
    confirmPassword,
  });
};

export const changePasswordInsideApp = ({
  confirmPassword,
  password,
  token,
}: NewPasswordRequestType) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    Omit<NewPasswordRequestType, 'token'>
  >(`/auth/reset-password-profile?token=${token}`, {
    password,
    confirmPassword,
  });
};

export const refreshTokenRequest = (token: string) => {
  return authServiceClient.get<
    { accessToken: string },
    AxiosResponse<{ accessToken: string }>,
    { token: string }
  >(`/auth/refresh`, { headers: { refresh: token } });
};

export const getVonageTokenRequest = () => {
  return authServiceClient.get<string, AxiosResponse<string>>(`/vonage/token`);
};

export const getTranslationRequest = (params: TranslationRequestType) => {
  return authServiceClient.get<
    TranslationResponseType[],
    AxiosResponse<TranslationResponseType[]>,
    TranslationRequestType
  >(`/azure/translate`, { params });
};

export const sendTextRequest = (text: string) => {
  return authServiceClient.post<any, AxiosResponse<any>, { text: string }>(`/`, { text });
};

export const getTextRequest = (id: string, isConversation: 0 | 1) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/vonage/output/${id}?isConversation=${isConversation}`,
  );
};

export const inviteMemberRequest = (data: InviteMemberRequestType) => {
  return authServiceClient.post<any, AxiosResponse<any>, InviteMemberRequestType>(
    `/vonage/invite-member`,
    data,
  );
};

export const getMeRequest = (token: string) => {
  return authServiceClient.get<
    LoginResponseType,
    AxiosResponse<UserLoginResponseType>,
    { token: string }
  >(`/auth/me`, { headers: { Authorization: `Bearer ${token}` } });
};

export const getStripeProductsRequest = () => {
  return authServiceClient.get<StripeProductType[], AxiosResponse<StripeProductType[]>>(
    `/stripe/products`,
  );
};

export const createCheckoutSessionRequest = (
  priceId: string,
  subscriptionType: SubscriptionType,
  country: string,
) => {
  return authServiceClient.post<
    CheckoutSessionType,
    AxiosResponse<CheckoutSessionType>,
    { priceId: string; subscriptionType: SubscriptionType; country: string }
  >(`/stripe/create-checkout-session`, { priceId, subscriptionType, country });
};

export const deleteSelfRequest = () => {
  return authServiceClient.delete<CommonResponseType, AxiosResponse<CommonResponseType>>(
    `/user`,
  );
};

export const deleteCallRequest = (commonId: string, totalCallTime: number) => {
  return authServiceClient.get<CommonResponseType, AxiosResponse<CommonResponseType>>(
    `/vonage/clear/${commonId}`,
    { params: { totalCallTime } },
  );
};

export const addTranscriptionRequest = (data: AddTranscriptionRequestType) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    AddTranscriptionRequestType
  >(`/call/add-transcription`, data);
};

export const resendLinkRequest = (data: {
  phone?: string;
  email?: string;
  password?: boolean;
}) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    { phone?: string; email?: string; password?: boolean }
  >(`/auth/reset-link`, data);
};

export const generatePaypalTokenRequest = () => {
  return authServiceClient.get<
    GeneratePaypalTokenResponseType,
    AxiosResponse<GeneratePaypalTokenResponseType>
  >(`/paypal/generate-token`);
};

export const addPaymentMethodNonceRequest = (data: AddPaymentMethodNonceRequestType) => {
  return authServiceClient.post<
    any,
    AxiosResponse<any>,
    AddPaymentMethodNonceRequestType
  >(`/paypal/checkout`, data);
};
