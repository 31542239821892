import { combineReducers } from 'redux';

import { adminReducer } from './admin/adminReducer';
import { authReducer } from './auth/authReducer';
import { callReducer } from './call/callReducer';

import { AuthActionType } from 'enums';
import { AppActionType } from 'state/types';

const appReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  call: callReducer,
});

export const rootReducer = (state: AppRootStateType, action: AppActionType) => {
  return appReducer(action.type === AuthActionType.LOG_OUT ? undefined : state, action);
};

export type AppRootStateType = ReturnType<typeof appReducer>;
