import React, { FC, FocusEventHandler } from 'react';

import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ActionMeta,
  // eslint-disable-next-line import/no-named-default
  default as ReactSelect,
  GroupBase,
  MenuPlacement,
  MultiValue,
  MultiValueRemoveProps,
  OnChangeValue,
  SingleValue,
} from 'react-select';

import './Select.scss';

export interface ISelectOption {
  value: string | number;
  label: string | number;
  image?: string;
}

export type isMultiType = false | true;

export type MultiValueRemoveType = React.ComponentType<
  MultiValueRemoveProps<any, isMultiType, GroupBase<any>>
>;

export interface ISelectProps {
  placeholder?: string;
  className?: string;
  label?: string;
  options: ISelectOption[];
  isMulti?: isMultiType;
  value?: SingleValue<ISelectOption> | MultiValue<ISelectOption>;
  defaultValue?: SingleValue<ISelectOption> | MultiValue<ISelectOption>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (
    value: OnChangeValue<ISelectOption, isMultiType>,
    action: ActionMeta<ISelectOption>,
  ) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  isOptionDisabled?: boolean;
  menuPlacement?: MenuPlacement;
  inputValue?: string;
  onInputChange?: (newValue: string) => void;
  error?: string;
  isClearable?: boolean;
  formatOptionLabel?: any;
  components?: any;
  styles?: any;
  onMenuScrollToBottom?: any;
  maxMenuHeight?: number;
}

export const Select: FC<ISelectProps> = props => {
  const {
    placeholder = '',
    options,
    label,
    className,
    value,
    onBlur,
    onFocus,
    onChange,
    defaultValue,
    isMulti = false,
    disabled,
    isOptionDisabled,
    inputValue,
    onInputChange,
    menuPlacement,
    error,
    isClearable,
    formatOptionLabel,
    components,
    styles,
    onMenuScrollToBottom,
    maxMenuHeight,
  } = props;

  return (
    <div
      className={classNames('SelectForm', className, {
        SelectForm__Error: error,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {label && <label className="SelectForm__Label">{label}</label>}
      <ReactSelect
        placeholder={placeholder}
        isMulti={isMulti}
        options={options}
        defaultValue={defaultValue}
        classNamePrefix="custom-select"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        isDisabled={disabled}
        menuIsOpen={isOptionDisabled ? false : undefined}
        menuPlacement={menuPlacement}
        inputValue={inputValue}
        onInputChange={onInputChange}
        isClearable={isClearable}
        formatOptionLabel={formatOptionLabel}
        components={components}
        styles={styles}
        onMenuScrollToBottom={onMenuScrollToBottom}
        maxMenuHeight={maxMenuHeight}
      />
      {error && <span className="TextError">{error}</span>}
    </div>
  );
};
