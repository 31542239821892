export enum CallActionType {
  ADD_RECIPIENT = 'ADD_RECIPIENT',
  CLEAR_RECIPIENTS = 'CLEAR_RECIPIENTS',
  HANG_UP_RECIPIENT = 'HANG_UP_RECIPIENT',
  CHANGE_RECIPIENT = 'CHANGE_RECIPIENT',
  CHANGE_RECIPIENT_CALL_STATUS = 'CHANGE_RECIPIENT_CALL_STATUS',
  MUTE_APP = 'MUTE_APP',
  CALL_TIME_OVER = 'CALL_TIME_OVER',
  ACTIVE_CALL = 'ACTIVE_CALL',
  START_CALL = 'START_CALL',
  SHOW_NUMBERS_WHEN_CALL = 'SHOW_NUMBERS_WHEN_CALL',
}
