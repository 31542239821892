import { CallActionType, CallStatus } from 'enums';
import { RecipientUserType } from 'state/reducers/call/types';

export const addRecipient = (recipient: RecipientUserType) =>
  ({ type: CallActionType.ADD_RECIPIENT, recipient } as const);
export const clearRecipients = () => ({ type: CallActionType.CLEAR_RECIPIENTS } as const);
export const hangUpRecipient = (recipientId: string) =>
  ({ type: CallActionType.HANG_UP_RECIPIENT, recipientId } as const);
export const changeRecipient = (
  recipientPhone: string,
  recipientId: string,
  conversationId: string,
) =>
  ({
    type: CallActionType.CHANGE_RECIPIENT,
    recipientPhone,
    recipientId,
    conversationId,
  } as const);
export const setMuteApp = (isMutedApp: boolean) =>
  ({ type: CallActionType.MUTE_APP, isMutedApp } as const);
export const setCallTimeOver = (isCallTimeOver: boolean) =>
  ({ type: CallActionType.CALL_TIME_OVER, isCallTimeOver } as const);
export const setActiveCall = (isActiveCall: boolean) =>
  ({ type: CallActionType.ACTIVE_CALL, isActiveCall } as const);
export const changeRecipientCallStatus = (
  recipientId: string,
  callStatus: CallStatus | undefined,
) =>
  ({
    type: CallActionType.CHANGE_RECIPIENT_CALL_STATUS,
    recipientId,
    callStatus,
  } as const);
export const setStartCall = (isStartCall: boolean) =>
  ({ type: CallActionType.START_CALL, isStartCall } as const);
export const setShowNumbersWhenCall = (isShowNumbersWhenCall: boolean) =>
  ({ type: CallActionType.SHOW_NUMBERS_WHEN_CALL, isShowNumbersWhenCall } as const);
