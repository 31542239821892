import React, { ReactNode, useMemo } from 'react';

import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import logOutLogo from 'assets/images/sideMenu/logout.svg';
import logo from 'assets/images/sideMenu/mid-logo.svg';
import userLogo from 'assets/images/user-logo.svg';
import { ReturnComponentType } from 'common';
import { MENU_ROUTES } from 'config';
import { Roles } from 'enums';
import { useAppSelector, useTypedDispatch } from 'hooks';
import { logOut, selectUser } from 'state';
import './AdminLayout.scss';

type AdminLayoutType = {
  children: ReactNode;
  className?: string;
};

export const AdminLayout = ({
  children,
  className,
}: AdminLayoutType): ReturnComponentType => {
  const dispatch = useTypedDispatch();
  const user = useAppSelector(selectUser);
  const onLogoutClick = () => {
    dispatch(logOut());
  };

  if (user?.role && user?.role !== Roles.ADMIN) {
    onLogoutClick();
  }

  const sideMenu = useMemo(() => {
    return MENU_ROUTES.map(menuRoute => {
      return (
        <div key={menuRoute.path} className="AdminLayout_SideMenu_Item">
          <NavLink
            to={menuRoute.path}
            className={navData =>
              navData.isActive
                ? 'AdminLayout_SideMenu_Item_Link AdminLayout_SideMenu_Item_Active'
                : 'AdminLayout_SideMenu_Item_Link AdminLayout_SideMenu_Item_Inactive'
            }
          >
            <img src={menuRoute.icon} alt={menuRoute.name} />
            <p>{menuRoute.name}</p>
            <div className="AdminLayout_SideMenu_Item_Empty" />
          </NavLink>
        </div>
      );
    });
  }, []);

  return (
    <section className={cn('AdminLayout', className)}>
      <section className="AdminLayout_SideMenu">
        <div>
          <img src={logo} alt="logo" className="AdminLayout_SideMenu_Logo" />
          {sideMenu}
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="AdminLayout_SideMenu_LogOut" onClick={onLogoutClick}>
          <img src={logOutLogo} alt="log-out" />
          <p>Log Out</p>
        </div>
      </section>

      <section className="AdminLayout_Box">
        <header className="AdminLayout_Box_Header">
          Welcome Back,&nbsp;<span>{user?.username ?? user?.email ?? user?.phone}</span>
          <img src={userLogo} alt="user-logo" />
        </header>
        <div className="AdminLayout_Box_Child">{children}</div>
      </section>
    </section>
  );
};
