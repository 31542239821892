import { AppRootStateType } from 'state/reducers';
import { RecipientUserType } from 'state/reducers/call/types';

export const selectRecipients = (state: AppRootStateType): RecipientUserType[] =>
  state.call.recipients;
export const selectMuteApp = (state: AppRootStateType): boolean => state.call.isMutedApp;
export const selectCallTimeOver = (state: AppRootStateType): boolean =>
  state.call.isCallTimeOver;
export const selectActiveCall = (state: AppRootStateType): boolean =>
  state.call.isActiveCall;
export const selectStartCall = (state: AppRootStateType): boolean =>
  state.call.isStartCall;
export const selectShowNumbersWhenCall = (state: AppRootStateType): boolean =>
  state.call.isShowNumbersWhenCall;
