import React from 'react';

import { Link } from 'react-router-dom';

import { CommonModalType, ReturnComponentType } from 'common';
import { CommonModal } from 'components/Modals/CommonModal';
import { Button } from 'ui-kit';

import './ShowTermsOfUseModal.scss';

type ShowTermsOfUseModalType = CommonModalType & {
  setValue?: (name: 'checked', value: boolean) => void;
};
export const ShowTermsOfUseModal = ({
  showModal,
  setShowModal,
  setValue,
}: ShowTermsOfUseModalType): ReturnComponentType => {
  const onOkClick = () => {
    if (setValue) {
      setValue('checked', true);
    }

    setShowModal(false);
  };
  const onCloseClick = () => {
    setShowModal(false);
  };

  return (
    <CommonModal showModal={showModal} closeModal={onCloseClick}>
      <section className="ShowTermsOfUseModal">
        <h2>1 Voice Ai Privacy Policy</h2>
        <div>
          The “Companies” (or “we” or “us” or “our” as defined in our Subscriber
          Agreement) know that you care about how your personal information is used and
          shared and we take your privacy seriously. Please read the following to learn
          more about how we use your personal information. All capitalized terms not
          defined in this Privacy Policy shall have the meanings ascribed to them in the
          Subscriber Agreement which may be found at:{' '}
          <Link to="http://www.1voice.ai">www.1Voice.ai</Link> and as may be modified
          thereafter (the “Subscriber Agreement”).
          <br />
          <br />
          By using any of our Services (including the 1 Voice Ai App and/or any of our
          other mobile apps offered from time to time by us in connection therewith) or by
          visiting, accessing or using our website at{' '}
          <Link to="http://www.1voice.ai">www.1Voice.ai</Link> you agree that you have
          reviewed and understood this Privacy Policy to your full satisfaction, that you
          consent and agree that you are subject to this Privacy Policy, and that we are
          permitted to collect, use and share information as outlined in this Privacy
          Policy. Your access to and use of the Services is subject to the Subscriber
          Agreement.
          <br />
          <br />
          Effective Date: August 28, 2023.
        </div>
        <h3>Privacy Policy Overview</h3>
        <div>
          This Privacy Policy covers our treatment of personally identifiable information
          (“Personal Information”) and other information that we gather when you access
          and/or use the Services. In addition, this Privacy Policy covers how you may
          control your Personal Information when you use our Services. This Privacy Policy
          does not apply to the practices of companies that we do not own or control
          (including any third- party products or services used for logging into the
          Services), or to individuals that we do not employ or manage.
        </div>
        <h3>Personal Information Collection and Usage</h3>
        <div>
          The information we gather from users and visitors enables us to personalize and
          improve our Services and allows our users to register to set up a user account
          to use our Services by voluntarily providing registration data and other
          requested information. Except as otherwise described in this Privacy Policy, we
          do not sell or rent your Personal Information to third parties without your
          consent.
          <br />
          <br />
          As described in this Privacy Policy, we share non-personally identifiable
          information with interested third parties to assist them in understanding the
          usage patterns for certain content, services, advertisements, promotions, and/or
          functionality of our Services. We may contract with a third party to track and
          analyze non-personally identifiable usage and volume statistical information
          from our visitors and customers to administer our website and Services and
          constantly improve their quality. All data collected by such party on our behalf
          is owned and used by us. We reserve the right to publish non-personally
          identifiable, summary information regarding our website visitors and users of
          our Services for promotional purposes. Please note that it is not Personal
          Information. It is only general summaries of the activities of our visitors and
          customers from which your Personal Information cannot be derived or discerned.
          <br />
          <br />
          We collect and use the following types of information from users of our Services
          and visitors to our website:
        </div>
        <h3>Information You Provide</h3>
        <div>
          We collect personally identifiable information in order to register your account
          as our customer, to process requests for Services and in connection with the
          provision of and billing for the Services. We ask you to provide your name,
          email address, street address, zip code and (when you are paying for Services)
          your credit card number.
          <br />
          <br />
          We provide your Personal Information to the Companies to provide the Services to
          you. In addition, we need to send your Personal Information to other companies
          who work as partners with us to provide Services to you. Unless we tell you
          differently, these other companies do not have any right to use the Personal
          Information we provide to them beyond what is necessary to assist us). This
          Personal Information is limited to only necessary information in reference to
          any Services you may obtain online.
          <br />
          <br />
          We receive and store any information you enter when using the Services or
          provide to us in any other way. The types of information collected in this
          manner include all information received upon account creation as well as
          provided to us while using the Services.
        </div>
        <h3>Automatically Collected Information</h3>
        <div>
          We and some third-party providers we work with automatically receive certain
          types of information whenever you communicate or otherwise interact with us
          (including by e-mail or chat communications) or use the Services. Automatic
          technologies and services we use may include, for example, web server logs/IP
          addresses, cookies, web beacons and call records.
          <br />
          <br />
          Web Server Logs/IP Addresses: When you use our website and Services we
          automatically receive and record information on our server logs from your
          browser including your IP address and the page you requested. An IP address is a
          number assigned to your computer whenever you access the Internet. All computer
          identification on the Internet is conducted with IP addresses, which allow
          computers and servers to recognize and communicate with each other. We collect
          IP addresses to conduct system administration and report aggregate anonymous
          information to affiliates, business partners and/or vendors to conduct site
          analysis and website performance review and to improve our Services.
          <br />
          <br />
          Cookies: Some of the advertisements and other services that may appear on our
          website and Services are delivered to you by Internet advertising companies.
          These companies utilize certain technologies including cookies to deliver
          advertisements and marketing messages and to collect anonymous information about
          your visit to our website and other websites and your use of our Services. A
          cookie is a piece of information that is placed automatically on your device
          drive when you access certain web services. The cookie uniquely identifies your
          device to the server and lets a web server know that you are the same person who
          previously accessed the server. This makes extended interaction possible.
          Cookies also allow us to store information on the server to help make the
          Services better for you and to conduct Services analysis.
          <br />
          <br />
          Web Beacons: We may use web beacons alone or in conjunction with cookies to
          compile information about your usage of our Services and interaction with emails
          from us. Web beacons are clear electronic images that can recognize certain
          types of information on your computer, such as cookies, when you viewed a
          particular website or mobile app tied to the web beacon, and a description of a
          website or mobile app tied to the web beacon. For example, we may place web
          beacons in marketing emails that notify us when you click on a link in the email
          that directs you to our website or Services. We may also use web beacons to
          operate and improve our website, email communications and Services.
          <br />
          <br />
          Call Records: In connection with the provision of our Services, we retain call
          records which can also contain Personal Information. We will not provide your
          call records to unaffiliated third parties without your consent.
        </div>
        <h3>Interest-Based Advertising</h3>
        <div>
          When you use our website and Services, we may collect information about your
          activities for use in providing you with advertising about products and services
          tailored to your individual interests. This section of our Privacy Policy
          provides details and explains how to exercise your choices.
          <br />
          <br />
          You may see certain ads on other websites based on your visits to our website or
          use of our Services because we participate in advertising networks administered
          by third-party vendors. Ad networks allow us to target our messaging to users
          through demographic, interest-based and contextual means. These networks track
          your online activities over time by collecting information through automated
          means, including through the use of cookies, web server logs and web beacons.
          The networks use this information to show you advertisements that are tailored
          to your individual interests. The information ad network vendors may collect
          includes information about your visits to websites that participate in the
          vendors&apos; advertising networks, such as the pages or advertisements you view
          and the actions you take on the websites. This data collection takes place both
          on our website and our mobile app(s) and on third-party websites and mobile
          app(s) that participate in the ad networks. This process also helps us track the
          effectiveness of our marketing efforts. To learn more about how to opt out of ad
          network interest-based advertising, please visit:{' '}
          <Link to="http://www.1voice.ai">http://www.1voice.ai</Link>
          <br />
          <br />
          We may use third-party web analytics services on our website and Services, such
          as those of Google Analytics, including the following Google Analytics
          advertising features: (i) Remarketing with Google Analytics, (ii) Google Display
          Network Impression Reporting, (iii) DoubleClick Platform integrations and (iv)
          Google Analytics Demographics and Interest Reporting. The analytics providers
          that administer these services use technologies such as cookies, web server logs
          and web beacons to help us analyze how visitors use our website and Services.
          The information collected through these means (including IP address) is
          disclosed to these analytics providers, who use the information to evaluate use
          of our website and Services.
          <br />
          <br />
          We may utilize Remarketing with Google Analytics to advertise online.
          Third-party vendors, including Google, may show our ads on sites across the
          Internet. We may utilize Google Display Network Impression Reporting to allow us
          to see the effects on our website and Services users of different types of ads
          and their usefulness. We may utilize data provided by Google Analytics
          Demographics and Interest Reporting to grow our website and Services and better
          customize our information to the interests of our customers and vistors. We and
          other third-party vendors, including Google, use first-party cookies (such as
          the Google Analytics cookie) and third-party cookies (such as the DoubleClick
          cookie) together to inform, optimize, and serve ads based on visitor&apos;s past
          visits to our website and past use of our Services. We may combine information
          we collect from you with information we receive from third parties and use it
          for the purposes described in this Privacy Policy. Google Analytics&apos; terms
          of use and privacy policy applies to their collection, use and disclosure of
          your information. To learn more about Google Analytics and how to opt out,
          please visit:{' '}
          <Link to="http://www.google.com/analytics/learn/privacy.html">
            http://www.google.com/analytics/learn/privacy.html
          </Link>
          . Visitors can also opt out of Google Analytics features, such as Google&apos;s
          use of cookies, using Ad Settings at{' '}
          <Link to="https://www.google.com/settings/ads">
            https://www.google.com/settings/ads
          </Link>
          .
        </div>
        <h3>Location Information</h3>
        <div>
          When you use the location-enabled feature of our Services, such as our mobile
          app, we may collect and process information about your actual location, like GPS
          signals sent by a mobile device. We do not share that information with third
          parties other than as required by law. You can choose to allow access to your
          current location provided by your mobile device using GPS or similar
          technologies to allow others to identify your location. By changing your mobile
          phone settings, you can turn off the mobile app&apos;s access to your location
          at any time. When you use our mobile app, we collect and use information about
          you in the same way and for the same purposes as we do when you use our website.
        </div>
        <h3>Sharing Policies</h3>
        <div>
          Below are the circumstances in which we may share your Personal Information.
          <br />
          <br />
          Agents, Consultants and Related Third Parties: We, like many businesses,
          sometimes hire third parties to perform certain business-related functions.
          Examples include mailing information, maintaining databases, processing payments
          and obtaining payment. We may provide access to your Personal Information to
          third parties who are performing services for us in connection with our website
          or the Services that you have requested. When we employ a third party to perform
          a function of this nature, we only provide them with the information that they
          reasonably require to perform their specific function.
          <br />
          <br />
          Financial Services Parties: The information you enter when making a request for
          Services, including Personal Information, will be shared with payment
          processors, financial gateways, and your credit card company to authorize credit
          card payments. Such information may also be shared with necessary third parties
          solely for the purpose or carrying out the transactions.
          <br />
          <br />
          Advertisers: We may aggregate Personal Information with Personal Information of
          other users of our Services and/or our website, and disclose such information in
          a non-personal manner, or in a personally identifiable manner, to advertisers
          and other third parties for marketing and promotional purposes. In these
          situations, we may disclose to these entities any information that could be used
          to identify you personally.
          <br />
          <br />
          Affiliates: We may share your Personal Information and non-personally
          identifiable information with the other Companies and with companies that are
          controlled by us, or under common control with us (our “Affiliates”), so that
          they and we can offer you Services that may be of interest to you and to process
          such information on our behalf.
          <br />
          <br />
          Sale of Business: If any or all of the Companies is sold or disposed of as a
          going concern, whether by merger, sale of assets, sale of equity, change of
          control, bankruptcy proceeding or otherwise, your information collected by us,
          including any related database of the Companies could, in whole or in part, be
          sold as part of that transaction. In the event we go through a business
          transition, such as a merger, acquisition by another company, sale of all or a
          portion of its assets or equity, bankruptcy, dissolution or similar transactions
          or proceedings, your Personal Information will likely be among the assets
          transferred.
          <br />
          <br />
          Legal Requirements: We may disclose Personal Information if required to do so by
          law or in the good faith belief that such action is necessary: (i) to conform to
          legal requirements or to respond to a subpoena, search warrant or other legal
          process received by us, whether or not a response is required by applicable law;
          (ii) to enforce our Subscriber Agreement and related guidelines or to protect
          our rights; (iii) to respond to your requests for customer service; or (iv) to
          protect the rights, property or safety of members of the public, users of the
          Services and our employees.
          <br />
          <br />
          You understand that calls to our customer support teams may be monitored and/or
          recorded for training and quality control purposes, and you consent to all such
          monitoring and/or recording.
          <br />
          <br />
          Except as set forth above, we will notify you before disclosing your Personal
          Information to third parties, and you will be able to prevent such disclosure.
        </div>
        <h3>Security</h3>
        <div>
          Upon signing up for an account with us you will be required to provide a
          password which will help protect your personal account from outside access. If
          you have any issues with your account protection, please notify us and we will
          assist in resetting your password.
          <br />
          <br />
          Keep in mind however, that no method of transmission over the Internet, or
          method of electronic storage, is 100% secure. We cannot enforce or control the
          security of the computers, electronic devices, or electronic communication
          method that a user may utilize to send e-mails and submit information to us over
          the Internet. Users are responsible for ensuring that the computers, mobile
          phones, electronic devices and electronic communication methods that users
          utilize will provide adequate security for communicating with us. To protect
          user privacy and security, a website user and mobile app user must keep his or
          her password confidential. We are not responsible for the disclosure or
          interception of your information before we receive it.
          <br />
          <br />
          We use commercially reasonable physical, managerial, and technical safeguards to
          preserve the security of your information. Once we receive information, we make
          commercially reasonable efforts to ensure the security of our systems. However,
          please note that this is not a guarantee that such information may not be
          accessed, disclosed, altered, or destroyed by breach of any of our physical,
          technical, or managerial safeguards.
        </div>
        <h3>Users Outside of The United States</h3>
        <div>
          Our website and Services are operated on servers located in the United States,
          and Personal Information is also stored on computers located in the United
          States. You consent to the transfer of such information to and from the United
          States and/or other countries for storage, processing and use by the Companies,
          Affiliates and business partners as provided in this Privacy Policy. We make no
          representation that the practices described in this Privacy Policy are compliant
          with laws outside of the United States that apply to the collection, security,
          use and disclosure of personal information.
        </div>
        <h3>Emails</h3>
        <div>
          We may send you administrative emails to verify your account, to assist with
          your requests for assistance with your account and/or the Services as well as
          help protect the identity of your account in case of fraud. By signing up for an
          account with us we may also send you updates and information on the latest
          releases and promotional emails to the given email address, with the option to
          opt-out of receiving promotional emails at any time.
        </div>
        <h3>Links To Other Sites</h3>
        <div>
          Other companies or organizations may have links to their websites on our website
          and Services. We are not responsible for how these other companies or
          organizations collect, use, disclose, or secure the information that you provide
          them. If you choose to access a third party website linked from our website or
          Services, you do so at your own risk and subject to any terms of service or
          privacy policy (if any) associated with such third party website.
        </div>
        <h3>Do Not Track Policy</h3>
        <div>
          Our website and Services are not designed to respond to &quot;do not track&quot;
          signals received from browsers.
        </div>
        <h3>Control Over Your Information</h3>
        <div>
          We give you the ability to edit your account information and preferences at any
          time. We allow you to access the following information about you for the purpose
          of viewing, and in certain situations, updating or deleting that information:
          <li>User e-mail address</li>
          <li>Username and password</li>
          You can change what information our mobile app sends to us. You can exercise
          this control by changing the settings of your mobile device.
          <br />
          <br />
          You maintain the right to deactivate your account at any time or to remove the
          magicApp from your mobile device entirely.
        </div>
        <h3>Changes to this Privacy Policy</h3>
        <div>
          We reserve the right to change this Privacy Policy. If we make any material
          changes in the way we use your Personal Information we will notify you by
          posting a prominent announcement on our website at least thirty (30) days prior
          to the change taking effect. Any revised Privacy Policy will only apply
          prospectively to Personal Information or non-personal information collected or
          modified after the effective date of the revised policy. You should periodically
          review the Privacy Policy available at our website for revisions. Use of our
          Services and/or our website after revision of the Privacy Policy signifies your
          consent to the revised Privacy Policy.
        </div>
        <h3>California Privacy Rights</h3>
        <div>
          We collect various types of Personal Information about you online as described
          in this Privacy Policy. Under California law, our customers who are residents of
          California may request certain information about our disclosure of Personal
          Information during the prior calendar year to third parties for their direct
          marketing purposes. For detailed information about the data collected refer to 1
          Voice Ai Privacy Notice for California Residents located at{' '}
          <Link to="http://www.1voice.ai">www.1Voice.ai</Link>
        </div>
        <h3>Questions or Concerns</h3>
        <div>
          If you have questions, concerns or suggestions regarding privacy on our
          Services, send them here: <Link to="http://www.1voice.ai">www.1Voice.ai</Link>{' '}
          We will reply in a timely manner in accordance with the message.
        </div>
        {setValue && (
          <Button onClick={onOkClick} className="ShowTermsOfUseModal_Button">
            Agree
          </Button>
        )}
      </section>
    </CommonModal>
  );
};
