export const convertTime = (time: number): { mins: number; sec: number } => {
  const mins = Math.floor(time / 60);

  return { mins, sec: time - mins * 60 };
};

export const convertSecToTime = (duration?: number) => {
  if (!duration) return '00:00:00';
  const hour = Math.floor(duration / 3600);
  const min = Math.floor(duration / 60) - hour * 60;
  const sec = duration - min * 60 - hour * 3600;

  return `${hour < 10 && hour >= 0 ? `0${hour}` : hour}:${
    min < 10 && min >= 0 ? `0${min}` : min
  }:${sec < 10 && sec >= 0 ? `0${sec}` : sec}`;
};
