import { authServiceClient } from './authServiceClient';

import { LsKeys, SsKeys } from 'enums';
import { handleErrorServiceInterceptor, restoreState, restoreStateFromSs } from 'utils';

export const authServiceInterceptor = (store: any) => {
  authServiceClient.interceptors.request.use(
    config => {
      const tokenInfo =
        restoreState(LsKeys.TOKEN_INFO) ?? restoreStateFromSs(SsKeys.TOKEN_INFO);

      if (tokenInfo && config.headers) {
        if (tokenInfo.accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${tokenInfo.accessToken}`;
        }
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
  const { dispatch } = store;

  authServiceClient.interceptors.response.use(
    res => {
      return res;
    },
    err => {
      handleErrorServiceInterceptor(err, dispatch);
    },
  );
};
