import React from 'react';

import { ReturnComponentType } from 'common';
import { TimerType } from 'hooks/useTimer/useTimer';
import './Timer.scss';

type TimerPropsType = { timer: TimerType };

export const Timer = ({ timer: { mins, sec } }: TimerPropsType): ReturnComponentType => {
  return (
    <div className="Timer">
      {`${mins < 10 ? `0${mins}` : mins}:${sec < 10 ? `0${sec}` : sec}`}
    </div>
  );
};
