import React, { memo } from 'react';

type FormatStringType = {
  oldString: string;
  search?: string;
  onClick?: () => void;
};

export const HighlightedString = memo(
  ({ oldString, search, onClick }: FormatStringType) => {
    if (!search?.trim()) {
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      return <span onClick={onClick}>{oldString}</span>;
    }

    const parts = oldString.split(new RegExp(`(${search.trim()})`, 'gi'));

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <span onClick={onClick}>
        {parts.map((part, i) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={
              part.toLowerCase() === search.trim().toLowerCase() && i < 3
                ? { color: '#DC3545' }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  },
);
