import {
  changePassword,
  logInRequest,
  refreshTokenRequest,
  restorePasswordRequest,
  signUpRequest,
  sendCodeRequest,
  getVonageTokenRequest,
  getTranslationRequest,
  sendTextRequest,
  getTextRequest,
  getMeRequest,
  ipRequest,
  getStripeProductsRequest,
  createCheckoutSessionRequest,
  changePasswordInsideApp,
  deleteSelfRequest,
  deleteCallRequest,
  addTranscriptionRequest,
  resendLinkRequest,
  generatePaypalTokenRequest,
  addPaymentMethodNonceRequest,
} from 'api';
import {
  LoginRequestType,
  NewPasswordRequestType,
  SignUpRequestType,
} from 'api/authApi/types';
import { LsKeys, SsKeys, SubscriptionType } from 'enums';
import {
  setAuthLoading,
  setLogIn,
  setLogOut,
  setNotification,
  setVonageToken,
  setUserInfo,
  setGeolocation,
  setPaypalToken,
  setError,
} from 'state/actions';
import { AppRootStateType } from 'state/reducers';
import { TypedDispatch } from 'state/types';
import { restoreState, restoreStateFromSs, saveState, saveStateToSs } from 'utils';

export const logIn =
  (data: LoginRequestType) =>
  async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const { rememberMe } = getState().auth;

    try {
      dispatch(setAuthLoading(true));

      const response = await logInRequest(data);

      const payload = {
        accessToken: response?.data.payload.accessToken,
        refreshToken: response?.data.payload.refreshToken,
      };

      if (response) {
        if (rememberMe) {
          saveState(LsKeys.TOKEN_INFO, payload);
        } else {
          saveStateToSs(SsKeys.TOKEN_INFO, payload);
        }

        dispatch(setLogIn(payload));
      }

      return response;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const logOut = () => (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    localStorage.removeItem(LsKeys.TOKEN_INFO);
    sessionStorage.removeItem(SsKeys.TOKEN_INFO);
    localStorage.removeItem(LsKeys.SHOW_RULES_MESSAGE);
    dispatch(setLogOut());
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const signUp = (params: SignUpRequestType) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await signUpRequest(params);

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const sendCode = (code: string) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await sendCodeRequest(code);

    if (response?.data.status) dispatch(setNotification(response?.data.message));

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};
export const restorePassword =
  (data: Omit<LoginRequestType, 'password'>) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await restorePasswordRequest(data);

      if (response?.data.status) {
        dispatch(setNotification(response?.data.message));
      }

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const sendNewPassword =
  (params: NewPasswordRequestType) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await changePassword(params);

      if (response?.data.status) dispatch(setNotification(response?.data.message));

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const sendNewPasswordInsideApp =
  (params: NewPasswordRequestType) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await changePasswordInsideApp(params);

      if (response?.data.status) dispatch(setNotification(response?.data.message));

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const refreshToken =
  () => async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const tokenInfo =
      restoreState(LsKeys.TOKEN_INFO) ?? restoreStateFromSs(LsKeys.TOKEN_INFO);
    const { rememberMe } = getState().auth;

    if (tokenInfo?.refreshToken) {
      try {
        dispatch(setAuthLoading(true));

        const response = await refreshTokenRequest(tokenInfo?.refreshToken);

        const payload = {
          accessToken: response?.data.accessToken,
          refreshToken: tokenInfo.refreshToken,
        };

        if (response) {
          if (rememberMe) {
            saveState(LsKeys.TOKEN_INFO, payload);
          } else saveStateToSs(SsKeys.TOKEN_INFO, payload);

          dispatch(setLogIn(payload));

          return response?.data?.accessToken;
        }
      } finally {
        dispatch(setAuthLoading(false));
      }
    }
  };

const getGeolocation = () => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await ipRequest();

    dispatch(
      setGeolocation({
        city: response?.data?.city,
        country: response?.data?.country_name,
      }),
    );
  } catch (error: any) {
    console.log('Location error', error);
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const getVonageToken = () => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    dispatch(getGeolocation());
    const response = await getVonageTokenRequest();

    dispatch(setVonageToken(response?.data));

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const getTranslation =
  (text: string, to: string) =>
  async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const { languageFrom: from } = getState().auth;

    try {
      dispatch(setAuthLoading(true));

      return await getTranslationRequest({ text, from, to });
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const sendText = (text: string) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));

    return await sendTextRequest(text);
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const getText = (id: string) => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));

    return await getTextRequest(id, 0);
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const getTextFromConversation =
  (id: string) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));

      return await getTextRequest(id, 1);
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const getMe =
  (token: string) =>
  async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const { rememberMe } = getState().auth;

    try {
      dispatch(setAuthLoading(true));

      const response = await getMeRequest(token);
      const payload = {
        accessToken: response?.data.accessToken,
        refreshToken: response?.data.refreshToken,
      };

      if (response) {
        if (rememberMe) {
          saveState(LsKeys.TOKEN_INFO, payload);
        } else {
          saveStateToSs(SsKeys.TOKEN_INFO, payload);
        }

        dispatch(setLogIn(payload));
        dispatch(setUserInfo(response.data.user));
      }

      return response;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const getStripeProducts = () => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));

    return await getStripeProductsRequest();
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const createCheckoutSession =
  (priceId: string, subscriptionType: SubscriptionType) =>
  async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const { country } = getState().auth.geolocation;

    try {
      dispatch(setAuthLoading(true));

      return await createCheckoutSessionRequest(priceId, subscriptionType, country);
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const deleteSelf = () => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await deleteSelfRequest();

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const deleteCall =
  (commonId: string, totalCallTime: number) => async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await deleteCallRequest(commonId, totalCallTime);

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const addTranscription =
  (transcription: string, commonId: string) =>
  async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const { languageFrom: lang } = getState().auth;

    try {
      dispatch(setAuthLoading(true));
      addTranscriptionRequest({ transcription, timestamp: Date.now(), commonId, lang });
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const resendLink =
  (data: { phone?: string; email?: string; password?: boolean }) =>
  async (dispatch: TypedDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await resendLinkRequest(data);

      if (response?.data.status) dispatch(setNotification(response?.data.message));

      return response?.status;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const generatePaypalToken = () => async (dispatch: TypedDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const response = await generatePaypalTokenRequest();

    dispatch(setPaypalToken(response?.data?.clientToken));

    return response?.status;
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const addPaymentMethodNonce =
  (paymentMethodNonce: string, amount: string, subscriptionType: SubscriptionType) =>
  async (dispatch: TypedDispatch, getState: () => AppRootStateType) => {
    const { country } = getState().auth.geolocation;
    const { accessToken } = getState().auth;

    try {
      dispatch(setAuthLoading(true));
      const response = await addPaymentMethodNonceRequest({
        payment_method_nonce: paymentMethodNonce,
        amount,
        subscriptionType,
        country,
      });

      if (!response?.data?.success) {
        dispatch(setError(response?.data?.message));
      }
      dispatch(setPaypalToken(''));
      dispatch(getMe(accessToken));

      return response?.data?.success;
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
